/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import BackButton from "../Common/BackButton";
import { PRODUCT_MANAGEMENT_URL } from "../Shared/constant";
import AvatarGet from "../Common/AvatarGet";
import { Scrollbars } from 'react-custom-scrollbars-2';
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import CommonLoader from "../Common/CommonLoader";

const ViewProduct = () => {
    const params = useParams();
    const { websocket } = useContext(WebSocketContext);
    const { selfInfo, viewProduct, brandlistall, modellistall,categoryAllList, userListIdWise, loaderMain, device_id, supplierListall } = useSelector((state) => state.adminReducers);

    // brand name get
    const BrandNameGet = (brand_id) => {
        return brandlistall?.filter((elm) => brand_id === elm?.id)[0]?.name;
    };

    // model name get
    const ModelNameGet = (model_id) => {
        return modellistall?.filter((elm) => model_id === elm?.id)[0]?.name;
    };

    const SupplierNameGet = (supplier_id) => {
        return supplierListall?.filter((elm) => supplier_id === elm?.id)[0]?.company;
    };

    const CategoryNameGet = (Category_id) => {
        return categoryAllList?.filter((elm) => Category_id === elm?.id)[0]?.name;
    };

    useEffect(() => {
        if(params?.roomId) {
            let param = { 
                "transmit": "broadcast", 
                "url": "get_product_detail",
                "request" : { 
                    "product_id" : params?.roomId, 
                    "DeviceId" : device_id
                }
            };
            wsSend_request(websocket, param);
        };
    }, [ params?.roomId ]);

    const getDimension = (dimension_st) => {
        if(dimension_st !== null && dimension_st !== undefined) {
            const DimesionData_ = dimension_st?.replace(/'/g, '"');
            const DimesionData_Json = JSON.parse(DimesionData_);

            return(
                <div className="column_count">
                    <div className="text-show">
                        <label>Weight</label>
                        {(DimesionData_Json?.weight === "" || DimesionData_Json?.weight === undefined) ? "-" : DimesionData_Json?.weight}
                    </div>
                    <div className="text-show">
                        <label>Height</label>
                        {(DimesionData_Json?.height === "" || DimesionData_Json?.height === undefined) ? "-" : DimesionData_Json?.height}
                    </div>
                    <div className="text-show">
                        <label>Length</label>
                        {(DimesionData_Json?.length === "" || DimesionData_Json?.length === undefined) ? "-" : DimesionData_Json?.length}
                    </div>
                    <div className="text-show">
                        <label>Width</label>
                        {(DimesionData_Json?.width === "" || DimesionData_Json?.width === undefined) ? "-" : DimesionData_Json?.width}
                    </div>
                </div>
            )
        } else {
            return(
                <div className="column_count">
                    <div className="text-show">
                        <label>Weight</label>
                        -
                    </div>
                    <div className="text-show">
                        <label>Height</label>
                        -
                    </div>
                    <div className="text-show">
                        <label>Length</label>
                        -
                    </div>
                    <div className="text-show">
                        <label>Width</label>
                        -
                    </div>
                </div>
            )
        }
    };

    return(<section className="dashboard_wrapper">
        <BackButton targeturl={PRODUCT_MANAGEMENT_URL} title={"Back"} />
        <div className="view_details">
            <Scrollbars 
                style={{ height: "calc(100vh - 153px)"}} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
            <div className="row" style={{ margin: "0 auto" }}>
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Picture</label>
                        <div className="product_avatar">
                            <AvatarGet type="product" url={viewProduct?.view_file_url} />
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                        <div className="row" >
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Item No	</label>
                                    <div className="text-show">
                                    {viewProduct?.item_code}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Purchase Invoice Number</label>
                                    <div className="text-show">
                                    {viewProduct?.pi_no === null ? "-" : viewProduct?.pi_no}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Product Name	</label>
                                    <div className="text-show">
                                    {viewProduct?.name}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Brand</label>
                                    <div className="text-show">
                                        {BrandNameGet(viewProduct?.brand) === null ? "-" : BrandNameGet(viewProduct?.brand)}		
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Model</label>
                                    <div className="text-show">
                                        {ModelNameGet(viewProduct?.model) === null ? "-" : ModelNameGet(viewProduct?.model)}		
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Supplier</label>
                                    <div className="text-show">
                                        {(SupplierNameGet(viewProduct?.supplier) === null || SupplierNameGet(viewProduct?.supplier) === undefined) ? "-" : SupplierNameGet(viewProduct?.supplier)}		
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Quantity</label>
                                    <div className="text-show">
                                    {viewProduct?.quantity === null ? "-" : viewProduct?.quantity}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Unit Cost Price</label>
                                    <div className="text-show">
                                    ${viewProduct?.unit_cost_price}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>HS Code</label>
                                    <div className="text-show">
                                        {viewProduct?.hs_code === null ? "-" : viewProduct?.hs_code}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>In-Coterm</label>
                                    <div className="text-show">
                                        {viewProduct?.incoterm === null ? "-" : viewProduct?.incoterm}
                                    </div>
                                </div>
                            </div>
                            {selfInfo?.is_admin && (<div className="col-md-6">
                                <div className="form-group">
                                    <label>Added By	</label>
                                    <div className="text-show">
                                    {userListIdWise[viewProduct?.added_by]?.email}	
                                    </div>
                                </div>
                            </div>)}
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Dimensions</label>
                                    {getDimension(viewProduct?.dimensions)}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Created At</label>
                                    <div className="text-show">
                                    {moment(viewProduct.created_at).format('LL')}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Brochure</label>
                                    <div className="text-show">
                                    {viewProduct?.brochure === null ? "-" : viewProduct?.brochure}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Catalog</label>
                                    <div className="text-show">
                                    {viewProduct?.catalog === null ? "-" : viewProduct?.catalog}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Category</label>
                                    <div className="text-show">
                                    {(CategoryNameGet(viewProduct?.category)=== null||CategoryNameGet(viewProduct?.category)=== undefined) ? "-" :CategoryNameGet(viewProduct?.category)}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>CE</label>
                                    <div className="text-show">
                                    {viewProduct?.ce === null ? "-" : viewProduct?.ce}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Client Code</label>
                                    <div className="text-show">
                                    {viewProduct?.client_code === null ? "-" : viewProduct?.client_code}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Country Of Origin</label>
                                    <div className="text-show">
                                    {viewProduct?.country_of_origin === null ? "-" : viewProduct?.country_of_origin}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>CS</label>
                                    <div className="text-show">
                                    {viewProduct?.cs === null ? "-" : viewProduct?.cs}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Currency</label>
                                    <div className="text-show">
                                    {viewProduct?.currency === null ? "-" : viewProduct?.currency}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Date</label>
                                    <div className="text-show">
                                        {(viewProduct.date === null || viewProduct.date === "") ? "-" : (viewProduct?.date?.split(" ")[0] === "Date" ? viewProduct.date : moment(new Date(viewProduct.date)).format('LL'))}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Department</label>
                                    <div className="text-show">
                                    {viewProduct.department === null ? "-" : viewProduct.department}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Fator</label>
                                    <div className="text-show">
                                    {viewProduct.fator === null ? "-" : viewProduct.fator}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Floor</label>
                                    <div className="text-show">
                                    {viewProduct.floor === null ? "-" : viewProduct.floor}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Iso</label>
                                    <div className="text-show">
                                    {viewProduct.iso === null ? "-" : viewProduct.iso}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Lead Time</label>
                                    <div className="text-show">
                                    {viewProduct.lead_time === null ? "-" : viewProduct.lead_time}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Lot Number</label>
                                    <div className="text-show">
                                    {viewProduct.lot_number === null ? "-" : viewProduct.lot_number}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>MAF</label>
                                    <div className="text-show">
                                    {viewProduct.maf === null ? "-" : viewProduct.maf}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>PC Code</label>
                                    <div className="text-show">
                                    {viewProduct.pc_code === null ? "-" : viewProduct.pc_code}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>PS</label>
                                    <div className="text-show">
                                    {viewProduct.ps === null ? "-" : viewProduct.ps}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Quantity</label>
                                    <div className="text-show">
                                    {viewProduct.quantity === null ? "-" : viewProduct.quantity}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Remarks</label>
                                    <div className="text-show">
                                    {viewProduct.remarks === null ? "-" : viewProduct.remarks}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>TCP USD</label>
                                    <div className="text-show">
                                    {viewProduct.tcp_usd === null ? "-" : viewProduct.tcp_usd}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>TSP USD</label>
                                    <div className="text-show">
                                    {viewProduct.tsp_usd === null ? "-" : viewProduct.tsp_usd}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>UCP USD</label>
                                    <div className="text-show">
                                    {viewProduct.ucp_usd === null ? "-" : viewProduct.ucp_usd}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>USP USD</label>
                                    <div className="text-show">
                                    {viewProduct.usp_usd === null ? "-" : viewProduct.usp_usd}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Unit</label>
                                    <div className="text-show">
                                    {viewProduct.unit === null ? "-" : viewProduct.unit}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Description ( English )	</label>
                                    <div className="text-show">
                                    {viewProduct?.description_english === null ? "-" : viewProduct?.description_english}	
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Description ( French )	</label>
                                    <div className="text-show">
                                    {viewProduct?.description_french === null ? "-" : viewProduct?.description_french}	
                                    </div>
                                </div>
                            </div>
                            {viewProduct?.meta_field?.length > 0 && (<div className="col-md-12">
                                <div className="form-group">
                                    <label>Metafield :</label>
                                    <div className="column_count_Main">
                                        <div className="row">
                                            {viewProduct?.meta_field?.map((elm, index) => {
                                                return(<div className="col-md-6" key={index}>
                                                <div className="text-show" style={{ marginBottom: "10px" }} key={index}>
                                                    <label style={{ fontSize: "12px" }}>{elm?.key}</label>
                                                    {elm?.value}
                                                </div>
                                            </div>)})}
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                        </div>
                </div>
            </div>
            </Scrollbars>
        </div>
        {/* loader start here */}
        {loaderMain && (<CommonLoader />)}
    </section>)
}

export default ViewProduct;