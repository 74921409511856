/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { DASHBOARD_URL, MODEL_BRAND_MANAGEMENT_URL, SUPPLIER_MANAGEMENT_URL, CART_MANAGEMENT_URL, PRODUCT_MANAGEMENT_URL, USER_MANAGEMENT_URL } from "../Shared/constant";
import BackButton from "../Common/BackButton";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import CheckValid from "../Common/CheckValid";
import InputField from "../Common/InputField";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useNavigate } from "react-router";

const Profile = () => {
    const Navigate = useNavigate();
    const { selfInfo, device_id } = useSelector((state) => state.adminReducers);
    const [ inputValue, setInputValue ] = useState({ 
        Fname: "", Lname: "", Address: "", Old_password: "", Password: ""
    });
    const { Fname, Lname, Address, Old_password, Password } = inputValue;
    const [ showpassword, setshowpassword ] = useState(false);
    const [ showOldpassword, setshowOldpassword ] = useState(false);
    const [ ChangePasswordState, setChangePasswordState ] = useState(false);
    const { websocket } = useContext(WebSocketContext);

    // error message
    const [ errorFname, setErrorFname ] = useState("");
    const [ errorLname, setErrorLname ] = useState("");
    const [ errorAddress, setErrorAddress ] = useState("");
    const [ errorOldPassword, setErrorOldPassword ] = useState("");
    const [ errorPassword, setErrorPassword ] = useState("");

    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line

    useEffect(() => {
        if(ChangePasswordState === true) {
            const MainWrapsId = document.getElementById("chnagepasswordblog");
            MainWrapsId.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [ ChangePasswordState ])

    useEffect(() => {
        if(selfInfo) {
            setInputValue({
                ...inputValue, 
                Fname: selfInfo?.first_name, 
                Lname: selfInfo?.last_name, 
                Address: selfInfo?.address === null ? "" : selfInfo?.address
            });
        };
    }, [ selfInfo ]);

    // Textfield common onchange function
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // show password
    const ShowHidePassword = () => {
        setshowpassword(!showpassword);
    };

    // show password
    const ShowOldHidePassword = () => {
        setshowOldpassword(!showOldpassword);
    };

    const RoutUrlSet = () => {
        if(selfInfo?.is_admin || (selfInfo?.permissions?.includes("dashboard"))) {
            return DASHBOARD_URL;
        } else {
            if(selfInfo?.permissions !== null) {
                if(selfInfo?.permissions?.split(",")[0] === "user-list" || 
                selfInfo?.permissions?.split(",")[0] === "add-user" || 
                selfInfo?.permissions?.split(",")[0] === "update-user" ||
                selfInfo?.permissions?.split(",")[0] === "delete-user"
                ) {
                    return USER_MANAGEMENT_URL;
                } else if(selfInfo?.permissions?.split(",")[0] === "product-list" || 
                selfInfo?.permissions?.split(",")[0] === "add-product" || 
                selfInfo?.permissions?.split(",")[0] === "update-product" ||
                selfInfo?.permissions?.split(",")[0] === "delete-product"
                ) {
                    return PRODUCT_MANAGEMENT_URL;
                } else if(selfInfo?.permissions?.split(",")[0] === "add-cart" || 
                selfInfo?.permissions?.split(",")[0] === "export-cart" || 
                selfInfo?.permissions?.split(",")[0] === "cart-list"
                ) {
                    return CART_MANAGEMENT_URL;
                } else if(selfInfo?.permissions?.split(",")[0] === "add-supplier" || 
                selfInfo?.permissions?.split(",")[0] === "update-supplier" || 
                selfInfo?.permissions?.split(",")[0] === "delete-supplier" ||
                selfInfo?.permissions?.split(",")[0] === "supplier-list"
                ) {
                    return SUPPLIER_MANAGEMENT_URL;
                }else if(selfInfo?.permissions?.split(",")[0] === "model-list" || 
                selfInfo?.permissions?.split(",")[0] === "add-model" || 
                selfInfo?.permissions?.split(",")[0] === "update-model" ||
                selfInfo?.permissions?.split(",")[0] === "delete-model" ||
                selfInfo?.permissions?.split(",")[0] === "brand-list" ||
                selfInfo?.permissions?.split(",")[0] === "add-brand" ||
                selfInfo?.permissions?.split(",")[0] === "update-brand" ||
                selfInfo?.permissions?.split(",")[0] === "delete-brand"
                ) {
                    return MODEL_BRAND_MANAGEMENT_URL;
                }
            } 
        }
    };

    // edit profile
    const EditProfile = () => {
        if(inputValue?.Fname !== "" || inputValue?.Lname !== "" || inputValue?.Address !== "") {
            let param = { 
                "transmit": "broadcast", 
                "url": "profile_update", 
                "request" :  { 
                    "first_name" : inputValue?.Fname, 
                    "last_name" : inputValue?.Lname, 
                    "address" : inputValue?.Address,
                    "DeviceId" : device_id
                }
            };
            wsSend_request(websocket, param);
            setInputValue({
                ...inputValue, 
                Fname: "",
                Lname: "",
                Address: ""
            });
            setTimeout(() => Navigate(RoutUrlSet()), 200);
        } else {
            CheckValid(inputValue?.Fname, {type: 'Fname', error: setErrorFname });
            CheckValid(inputValue?.Lname, {type: 'Lname', error: setErrorLname });
            CheckValid(inputValue?.Address, {type: 'Address', error: setErrorAddress });
        };
    };

    // change password api 
    const EditProfilePassword = () => {
        if(inputValue?.Old_password !== "" &&  inputValue?.Password !== "") {
            if(PasswordRegex.test(inputValue?.Old_password) === true && PasswordRegex.test(inputValue?.Password) === true) {
                if(inputValue?.Old_password !==  inputValue?.Password) {
                    let param = { 
                        "transmit": "broadcast", 
                        "url": "profile_update", 
                        "request" :  { 
                            "old_password" : inputValue?.Old_password,
                            "new_password" : inputValue?.Password,
                            "DeviceId" : device_id
                        }
                    };
                    wsSend_request(websocket, param);
                    setInputValue({
                        ...inputValue, 
                        Old_password: "",
                        Password: ""
                    });
                    // setTimeout(() => Navigate(DASHBOARD_URL), 200);
                } else {
                    if(inputValue?.Old_password === inputValue?.Password) {
                        setErrorPassword("Old Password and new password cannot be same.");
                    } else {
                        setErrorPassword("");
                    }
                }
            } else {
                CheckValid(inputValue?.Old_password, {type: 'oldpassword', error: setErrorOldPassword });
                CheckValid(inputValue?.Password, {type: 'newpassword', error: setErrorPassword });
            }
        } else {
            CheckValid(inputValue?.Old_password, {type: 'oldpassword', error: setErrorOldPassword });
            CheckValid(inputValue?.Password, {type: 'newpassword', error: setErrorPassword });
        };
    }

    return(<section className="dashboard_wrapper">
        <BackButton targeturl={RoutUrlSet()} title={"Back"} />
        <Scrollbars 
            style={{ height: "calc(100% - 53px)" }} 
            className="ScrollbarsWrapper"
            renderView={props => <div {...props} className="view"/>}
        >
            <div className="form-sections">
                <div className="row">
                    <div className="col-md-6">
                        <InputField
                            type="text"
                            value={Fname}
                            className={errorFname !== "" ? "form-control error-form" : "form-control"}
                            placeholder="Enter First Name"
                            label="First Name"
                            name="Fname"
                            onChange={handleChange}
                            onKeyUp={(e) => CheckValid(e.target.value, {type: 'Fname', error: setErrorFname})}
                            onKeyDown={EmptySpaceFieldValid}
                            errormessage={errorFname}
                        />
                    </div>
                    <div className="col-md-6">
                        <InputField
                            type="text"
                            value={Lname}
                            className={errorLname !== "" ? "form-control error-form" : "form-control"}
                            placeholder="Enter Last Name"
                            label="Last Name"
                            name="Lname"
                            onChange={handleChange}
                            onKeyUp={(e) => CheckValid(e.target.value, {type: 'Lname', error: setErrorLname})}
                            onKeyDown={EmptySpaceFieldValid}
                            errormessage={errorLname}
                        />
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Email</label>
                            <div className="inputText">
                                {selfInfo?.email === null ? "-" : selfInfo?.email}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Mobile No</label>
                            <div className="inputText">
                                +{selfInfo?.phone_code === null ? "1" : selfInfo?.phone_code} &nbsp; {selfInfo?.phone === null ? "-" : selfInfo?.phone}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <InputField
                            type="text"
                            value={Address}
                            className={errorAddress !== "" ? "form-control error-form" : "form-control"}
                            placeholder="Enter Address"
                            label="Address"
                            name="Address"
                            onChange={handleChange}
                            onKeyUp={(e) => CheckValid(e.target.value, {type: 'Address', error: setErrorAddress})}
                            onKeyDown={EmptySpaceFieldValid}
                            errormessage={errorAddress}
                        />
                    </div>
                    <div className="col-md-12">
                        <div className="form-group-btn" style={{ textAlign: "right" }}>
                            <button type="button" className="btn addbtncmn" onClick={() => EditProfile()}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-sections">
            <h4 className="headecenter">Change Password</h4>
                <div className="row">
                    <div className="col-md-6">
                        <InputField
                            type={showOldpassword ? "text" : "password"}
                            value={Old_password}
                            className={errorOldPassword !== "" ? "form-control error-form" : "form-control"}
                            placeholder="Enter Old password"
                            label="Enter Old Password"
                            name="Old_password"
                            onChange={handleChange}
                            onKeyUp={(e) => CheckValid(e.target.value, {type: 'oldpassword', error: setErrorOldPassword})}
                            onKeyDown={EmptySpaceFieldValid}
                            errormessage={errorOldPassword}
                            onClick={ShowOldHidePassword}
                            showOldpassword={showOldpassword}
                        />
                    </div>
                    <div className="col-md-6">
                        <InputField
                            type={showpassword ? "text" : "password"}
                            value={Password}
                            className={errorPassword !== "" ? "form-control error-form" : "form-control"}
                            placeholder="Enter New password"
                            label="Enter New Password"
                            name="Password"
                            onChange={handleChange}
                            onKeyUp={(e) => CheckValid(e.target.value, {type: 'newpassword', error: setErrorPassword})}
                            onKeyDown={EmptySpaceFieldValid}
                            errormessage={errorPassword}
                            onClick={ShowHidePassword}
                            showpassword={showpassword}
                        />
                    </div>
                    <div className="col-md-12">
                        <div className="form-group-btn" style={{ textAlign: "right" }}>
                            <button type="button" className="btn addbtncmn" onClick={() => EditProfilePassword()}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Scrollbars>
    </section>)
}

export default Profile;