/* eslint-disable */
import React from "react";
import { Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Image } from "react-bootstrap";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { ADD_PRODUCT_MANAGEMENT_URL, ADD_USER_URL, EDIT_PRODUCT_MANAGEMENT_URL, EDIT_SUPPLIER_MANAGEMENT_URL, CART_MANAGEMENT_URL, VIEW_PRODUCT_MANAGEMENT_URL ,VIEW_SUPPLIER_MANAGEMENT_URL,MODEL_BRAND_MANAGEMENT_URL, DASHBOARD_URL, PRODUCT_MANAGEMENT_URL, SUPPLIER_MANAGEMENT_URL, ADD_SUPPLIER_MANAGEMENT_URL, USER_MANAGEMENT_URL, VIEW_MODEL_BRANDS_MANAGEMENT_URL, EDIT_USER_URL, CATEGORY_MANAGEMENT_URL, SETTINGS_MANAGEMENT_URL } from "../Shared/constant";
import BrandIcon from "../../assets/img/brand-image.png";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentAddedBrandModel, selectCurrentAddedBrand, selectCurrentAddedSupplier, getSelectedCurrentBrandIDs, selectCurrentAddedCategory } from "../../redux/actions/adminActions";

const Sidebar = (props) => {
    const { setShowSidebar } = props;
    const Dispatch = useDispatch();
    const RouteUrl = useLocation()?.pathname;
    const RouteUrlEditView = "/" + RouteUrl.split("/")[1];
    const { selfInfo } = useSelector((state) => state.adminReducers);

    // sidebar close
    const SidbarActive = () => {
        Dispatch(selectCurrentAddedBrand({}));
        Dispatch(selectCurrentAddedBrandModel({}));
        Dispatch(getSelectedCurrentBrandIDs(""));
        Dispatch(selectCurrentAddedCategory({}));
        Dispatch(selectCurrentAddedSupplier({}));
    };

    // sidebar close
    const SidbarActiveClose = () => {
        setShowSidebar(false)
        Dispatch(selectCurrentAddedBrand({}));
        Dispatch(selectCurrentAddedBrandModel({}));
        Dispatch(getSelectedCurrentBrandIDs(""));
        Dispatch(selectCurrentAddedCategory({}));
        Dispatch(selectCurrentAddedSupplier({}));
    };

    return(<div className="sidebar">
        {/* <div className="navbarHeader">
            <h1>Logo</h1>
        </div> */}
        {window.screen.width < 901 && (<button className="close_sidebarbtn" onClick={() => SidbarActiveClose()}><i className="bi bi-x-lg"></i> </button>)}
        <Scrollbars 
            style={{ height: "calc(100vh)" }} 
            renderView={props => <div {...props} className="view"/>}
            className="ScrollbarsSidebar"
        >
            {window.screen.width < 901 ? (<Nav defaultActiveKey={DASHBOARD_URL} className="flex-column">
                {(selfInfo?.is_admin || selfInfo?.permissions?.includes("dashboard")) && (<Link to={DASHBOARD_URL} onClick={() => SidbarActiveClose()} className={(RouteUrl === DASHBOARD_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                    <i className="bi bi-speedometer2"></i>
                    <span>Dashboard</span>
                </Link>)}
                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("user-list") || selfInfo?.permissions?.includes("add-user") || selfInfo?.permissions?.includes("update-user") || selfInfo?.permissions?.includes("delete-user"))) && (<>
                    {selfInfo?.is_admin && (<Link to={USER_MANAGEMENT_URL} onClick={() => SidbarActiveClose()} className={(RouteUrl === USER_MANAGEMENT_URL || RouteUrl === ADD_USER_URL || RouteUrlEditView === EDIT_USER_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                        <i className="bi bi-people"></i>
                        <span>User Management</span>
                    </Link>)}
                </>)}
                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("product-list") || selfInfo?.permissions?.includes("add-product") || selfInfo?.permissions?.includes("update-product") || selfInfo?.permissions?.includes("delete-product"))) && (<Link to={PRODUCT_MANAGEMENT_URL} onClick={() => SidbarActiveClose()} className={(RouteUrl === PRODUCT_MANAGEMENT_URL || RouteUrl === ADD_PRODUCT_MANAGEMENT_URL || RouteUrlEditView === VIEW_PRODUCT_MANAGEMENT_URL || RouteUrlEditView === EDIT_PRODUCT_MANAGEMENT_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                    <i className="bi bi-archive"></i>
                    <span>Product Management</span>
                </Link>)}
                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("add-category") || selfInfo?.permissions?.includes("update-category") || selfInfo?.permissions?.includes("delete-category"))) && (
                    <Link to={CATEGORY_MANAGEMENT_URL} onClick={() => SidbarActiveClose()}  className={(RouteUrl === CATEGORY_MANAGEMENT_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                        <i className="bi bi-layout-text-sidebar-reverse"></i>
                        <span>Category Management</span>
                    </Link>
                )}
                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("add-cart") || selfInfo?.permissions?.includes("export-cart") || selfInfo?.permissions?.includes("cart-list"))) && (<Link to={CART_MANAGEMENT_URL} onClick={() => SidbarActiveClose()} className={(RouteUrl === CART_MANAGEMENT_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                    <i className="bi bi-bag"></i>
                    <span>Cart</span>
                </Link>)}
                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("add-supplier") || selfInfo?.permissions?.includes("update-supplier") || selfInfo?.permissions?.includes("delete-supplier") || selfInfo?.permissions?.includes("supplier-list"))) && (<Link to={SUPPLIER_MANAGEMENT_URL} onClick={() => SidbarActiveClose()} className={(RouteUrl === SUPPLIER_MANAGEMENT_URL || RouteUrl === ADD_SUPPLIER_MANAGEMENT_URL || RouteUrlEditView ===  VIEW_SUPPLIER_MANAGEMENT_URL || RouteUrlEditView === EDIT_SUPPLIER_MANAGEMENT_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                    <i className="bi bi-truck"></i>
                    <span>Supplier</span>
                </Link>)}
                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("model-list") || selfInfo?.permissions?.includes("add-model") || selfInfo?.permissions?.includes("update-model") || selfInfo?.permissions?.includes("delete-model") || selfInfo?.permissions?.includes("brand-list") || selfInfo?.permissions?.includes("add-brand") || selfInfo?.permissions?.includes("update-brand") || selfInfo?.permissions?.includes("delete-brand"))) && (<Link to={MODEL_BRAND_MANAGEMENT_URL} onClick={() => SidbarActiveClose()} className={(RouteUrl === MODEL_BRAND_MANAGEMENT_URL || RouteUrlEditView ===  VIEW_MODEL_BRANDS_MANAGEMENT_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                    <Image src={BrandIcon} alt="model brand" />&nbsp;&nbsp;
                    <span>Brand & Model</span>
                </Link>)}
                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("product-setting-list") || selfInfo?.permissions?.includes("add-product-setting") || selfInfo?.permissions?.includes("update-product-setting") || selfInfo?.permissions?.includes("delete-product-setting"))) && (
                    <Link to={SETTINGS_MANAGEMENT_URL} onClick={() => SidbarActiveClose()} className={RouteUrl === SETTINGS_MANAGEMENT_URL ? "nav-link active" : "nav-link"}>
                        <i className="bi bi-gear"></i>
                        <span>Fields Management</span>
                    </Link>
                )}
            </Nav>) : (<Nav defaultActiveKey={DASHBOARD_URL} className="flex-column">
                {(selfInfo?.is_admin || selfInfo?.permissions?.includes("dashboard")) && (
                    <Link to={DASHBOARD_URL} onClick={() => SidbarActive()} className={(RouteUrl === DASHBOARD_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                        <i className="bi bi-speedometer2"></i>
                        <span>Dashboard</span>
                    </Link>)}
                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("user-list") || selfInfo?.permissions?.includes("add-user") || selfInfo?.permissions?.includes("update-user") || selfInfo?.permissions?.includes("delete-user"))) && (<>
                    {selfInfo?.is_admin && (<Link to={USER_MANAGEMENT_URL} onClick={() => SidbarActive()} className={(RouteUrl === USER_MANAGEMENT_URL || RouteUrl === ADD_USER_URL || RouteUrlEditView === EDIT_USER_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                        <i className="bi bi-people"></i>
                        <span>User Management</span>
                    </Link>)}
                </>)}
                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("product-list") || selfInfo?.permissions?.includes("add-product") || selfInfo?.permissions?.includes("update-product") || selfInfo?.permissions?.includes("delete-product"))) && (
                    <Link to={PRODUCT_MANAGEMENT_URL} onClick={() => SidbarActive()} className={(RouteUrl === PRODUCT_MANAGEMENT_URL || RouteUrl === ADD_PRODUCT_MANAGEMENT_URL || RouteUrlEditView === VIEW_PRODUCT_MANAGEMENT_URL || RouteUrlEditView === EDIT_PRODUCT_MANAGEMENT_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                        <i className="bi bi-archive"></i>
                        <span>Product Management</span>
                    </Link>)}
                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("add-category") || selfInfo?.permissions?.includes("update-category") || selfInfo?.permissions?.includes("delete-category"))) && (
                    <Link to={CATEGORY_MANAGEMENT_URL} onClick={() => SidbarActive()} className={(RouteUrl === CATEGORY_MANAGEMENT_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                        <i className="bi bi-layout-text-sidebar-reverse"></i>
                        <span>Category Management</span>
                    </Link>
                )}
                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("add-cart") || selfInfo?.permissions?.includes("export-cart") || selfInfo?.permissions?.includes("cart-list"))) && (
                    <Link to={CART_MANAGEMENT_URL} onClick={() => SidbarActive()} className={(RouteUrl === CART_MANAGEMENT_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                        <i className="bi bi-bag"></i>
                        <span>Cart</span>
                    </Link>)}
                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("add-supplier") || selfInfo?.permissions?.includes("update-supplier") || selfInfo?.permissions?.includes("delete-supplier") || selfInfo?.permissions?.includes("supplier-list"))) && (
                    <Link to={SUPPLIER_MANAGEMENT_URL} onClick={() => SidbarActive()} className={(RouteUrl === SUPPLIER_MANAGEMENT_URL || RouteUrl === ADD_SUPPLIER_MANAGEMENT_URL || RouteUrlEditView ===  VIEW_SUPPLIER_MANAGEMENT_URL || RouteUrlEditView === EDIT_SUPPLIER_MANAGEMENT_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                        <i className="bi bi-truck"></i>
                        <span>Supplier</span>
                    </Link>)}
                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("model-list") || selfInfo?.permissions?.includes("add-model") || selfInfo?.permissions?.includes("update-model") || selfInfo?.permissions?.includes("delete-model") || selfInfo?.permissions?.includes("brand-list") || selfInfo?.permissions?.includes("add-brand") || selfInfo?.permissions?.includes("update-brand") || selfInfo?.permissions?.includes("delete-brand"))) && (
                    <Link to={MODEL_BRAND_MANAGEMENT_URL} onClick={() => SidbarActive()} className={(RouteUrl === MODEL_BRAND_MANAGEMENT_URL || RouteUrlEditView ===  VIEW_MODEL_BRANDS_MANAGEMENT_URL || RouteUrl === "/") ? "nav-link active" : "nav-link"}>
                        <Image src={BrandIcon} alt="model brand" />
                        <span>Brand & Model</span>
                    </Link>)}
                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("product-setting-list") || selfInfo?.permissions?.includes("add-product-setting") || selfInfo?.permissions?.includes("update-product-setting") || selfInfo?.permissions?.includes("delete-product-setting"))) && (
                    <Link to={SETTINGS_MANAGEMENT_URL} onClick={() => SidbarActive()} className={RouteUrl === SETTINGS_MANAGEMENT_URL ? "nav-link active" : "nav-link"}>
                        <i className="bi bi-gear"></i>
                        <span>Fields Management</span>
                    </Link>)}
            </Nav>)}
        </Scrollbars>
    </div>)
};

export default Sidebar;