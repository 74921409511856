import { EXPORT_PRODUCT_FILE_API_URL } from "../components/Shared/constant";

const CreateUserAPI = async (payload, accessToken) => {
    const responce = window.axios.post(EXPORT_PRODUCT_FILE_API_URL, payload, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + accessToken,
    }}).then(function (result) {
        return {
          ...result.data,
          status:200
        };
    }).catch(function (result) {
        return {
          ...result?.response?.data,
          status:result?.response?.status
        }
    });
    return responce;
    // const responce = window.axios.post(EXPORT_PRODUCT_FILE_API_URL, payload, {
    //   headers: {
    //     // 'Content-Type': 'application/json',
    //     // 'Accept': 'application/json',
    //     'Authorization': 'Bearer ' + accessToken,
    //   }})
    //   .then( res => res.blob() )
    //   .then( blob => {
        
    //     let file = window.URL.createObjectURL(blob);
    //     var a = document.createElement("a");
    //     a.href = URL.createObjectURL(file);
    //     a.download = "sample.xlsx";
    //     a.click();
    //     // return file;
    //   }).catch((error)=>{return error;});
    //   return responce;
};
  
export default CreateUserAPI;