import React, { useState } from "react";
import InputField from "../Common/InputField";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { Image } from "react-bootstrap";
import ForgotPasswordImage from "../../assets/img/forgot_password.png";
import { Link, useNavigate } from "react-router-dom";
import { LOGIN_URL } from "../Shared/constant";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ForgotPasswordAPI from "../../Api/ForgotPasswordAPI";

const ForgotPassword = () => {
    const [ inputValue, setInputValue ] = useState({ Email : "" });
    const { Email } = inputValue;
    const Navigate = useNavigate();

    // Regex
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line

    // error message
    const [ errorEmail, setErrorEmail ] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // login function
    const forgotPasswordfunct = async () => {
        if(inputValue?.Email !== "") {
            if(EmailRegex.test(inputValue?.Email) === true) {
                const jsonData = JSON.stringify({ email: inputValue?.Email });
                const response = await ForgotPasswordAPI(jsonData);
                if(response?.status === 200){
                    toast.success(response.msg);
                    setTimeout(() => Navigate(LOGIN_URL), 200);
                    clearForm();
                } else {
                    clearForm();
                    toast.error(response.error);
                }
            } else {
                CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
            }
        } else {
            CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
        }
    };

    // clear form
    const clearForm = () => {
        setInputValue({...inputValue, Email : ""});
        setErrorEmail("");
    };
    
    return(<section className="login_wrapper">
    <div className="login_wrap_slider">
        <Image src={ForgotPasswordImage} alt="login image" />
        <h4>Forgot Password ?</h4>
        <p>Enter your email and we'll send you a link to reset your password.</p>
    </div>
    <div className="login_form_wraps">
        <div className="loginTEXTS">
            {/* <h1>Medical Equipment</h1> */}
            <h4>Forgot Password ?</h4>
        </div>
        <InputField 
            type="text"
            value={Email}
            className={errorEmail !== "" ? "form-control error-form" : "form-control"}
            placeholder="Enter email"
            label="Email address"
            name="Email"
            onChange={handleChange}
            onKeyUp={(e) => CheckValid(e.target.value, {type: 'email', error: setErrorEmail})}
            onKeyDown={EmptySpaceFieldValid}
            errormessage={errorEmail}
        />
        <div className="form-group-btn">
            <button className="btn loginCmn_btn" onClick={() => forgotPasswordfunct()}>Create Password</button>
            <Link to={LOGIN_URL} className="btn forgot_password_link"><i className="bi bi-arrow-left"></i> Back</Link>
        </div>
    </div>
</section>)
};

export default ForgotPassword;