import { IPMPORT_PRODUCT_FILE_API_URL } from "../components/Shared/constant";

const ImportProductAPI = async (accessToken, payload) => {
    const responce = window.axios.post(IPMPORT_PRODUCT_FILE_API_URL, payload, {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
    }}).then(function (result) {
        return {
          ...result.data,
          status:200
        };
    }).catch(function (result) {
        return {
          ...result?.response?.data,
          status:result?.response?.status
        }
    });
    return responce;
};
  
export default ImportProductAPI;
  