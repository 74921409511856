/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { SUPPLIER_MANAGEMENT_URL } from "../Shared/constant";
import BackButton from "../Common/BackButton";
import InputField from "../Common/InputField";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { Dropdown } from 'react-bootstrap';
import CountrySelectInput from "../Common/CountrySelectInput/CountryMobileInput";
import { Scrollbars } from 'react-custom-scrollbars-2';
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import Countries from "../Common/CountrySelectInput/CountryCodes.json";
import CaretDown from "../../assets/img/caret-down.svg";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import CommonLoader from "../Common/CommonLoader";
import { parsePhoneNumber, getRegionCodeForCountryCode } from "awesome-phonenumber";

const EditSupplier = (props) => {
    const { AddSupplierForPorduct, handleClose } = props;
    const { device_id, supplierdetails, loaderMain } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Navigate = useNavigate();
    const Params = useParams();
    const Location = useLocation()?.pathname?.split("/")[1];
    const [ inputValue, setInputValue ] = useState({ SupplierName: "", CompanyName: "", Email: "", Address: "", VATRegistrationNo: "", RegistrationNo: "", Title: "", WebSiteUrl: "" });
    const { Email, SupplierName, Address, CompanyName, VATRegistrationNo, RegistrationNo, Title, WebSiteUrl } = inputValue;
    const [ PhoneNumber, setPhoneNumber ] = useState({
        phone_code: "1",
        phone: ""
    });
    const [ CountrySelect, setCountrySelect ] = useState("");
    const [ selectTab, setSelectTab ] = useState("Add_Supplier");
    const FlagsUrl = "https://purecatamphetamine.github.io/country-flag-icons/3x2/";
 
    // Regex
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line

    // error message
    const [ errorEmail, setErrorEmail ] = useState("");
    const [ errorSupplierName, setErrorSupplierName ] = useState("");
    const [ errorAddress, setErrorAddress ] = useState("");
    const [ errorPhone, setErrorPhone ] = useState("");
    const [ errorCompanyName, setErrorCompanyName ] = useState("");
    const [ errorVATRegistrationNo, setErrorVATRegistrationNo ] = useState("");
    const [ errorRegistrationNo, setErrorRegistrationNo ] = useState("");
    const [ errorWebSiteUrl, setErrorWebSiteUrl ] = useState("");
    const [ errorTitle, setErrorTitle ] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // get supplier details
    useEffect(() => {
        if(Location === "edit-supplier") {
            if(Params?.roomId) {
                let param = { 
                    "transmit": "broadcast", 
                    "url": "supplier_detail",
                    "request" : { 
                        "supplier_id" : Params?.roomId,
                        "DeviceId" : device_id
                    }
                };
                wsSend_request(websocket, param);
            };
        }
    }, [ Params?.roomId, Location ]);

    // add more contact function
    const [ AddMoreContactState, setAddMoreContactState ] = useState([]);
    let AddMoreContactArray = [...AddMoreContactState];

    const AddMoreContact = () => {
        var MoreContactObj = { 
            name: "", 
            title: "", 
            email: "", 
            phone_code: "1", 
            phone_flag: "US",
            phone: "",
            id: (Math.random() + 1).toString(36).substring(7),
        }
        AddMoreContactArray.push(MoreContactObj);
        setAddMoreContactState(AddMoreContactArray);
    };

    // get email name and phone, title 
    const GetContactUpdate = (e, id, url) => {
        const NewUpdatedContactArray = AddMoreContactArray.map((elm) => {
            if(elm?.id === id) {
                if(url === "Name") {
                    return{
                        ...elm,
                        name: url === "Name" && (e.target?.value === "" ? "" : e.target?.value)
                    }
                } else if(url === "Title") {
                    return{
                        ...elm,
                        title: url === "Title" && (e.target?.value === "" ? "" : e.target?.value)
                    }
                } else if(url === "Email") {
                    return{
                        ...elm,
                        email: url === "Email" && (e.target?.value === "" ? "" : e.target?.value)
                    }
                } else {
                    return{
                        ...elm,
                        phone: url === "Phone" && (e.target?.value === "" ? "" : e.target?.value),  
                    }
                }
            } else 
            return elm;
        });
        setAddMoreContactState(NewUpdatedContactArray);
    };

    // select country and flag select function
    const SelectCountryCodeContactDetails = (flag, index) => {
        const UpdatePhoneArray = AddMoreContactArray.map((elm) => {
            if(elm?.id === index) {
                return{
                    ...elm,
                    phone_flag:  flag?.country_code,
                    phone_code: flag?.phone_code,
                }
            } else 
            return elm;
        })
        setAddMoreContactState(UpdatePhoneArray);
    };

    // get edit current details
    useEffect(() => {
        if(Location === "edit-supplier") {
            if(supplierdetails) {
                setInputValue({...inputValue, 
                    SupplierName : supplierdetails?.name, 
                    CompanyName: supplierdetails?.company, 
                    Email: supplierdetails?.email === null ? "" : supplierdetails?.email, 
                    Address: supplierdetails?.address === null ? "" : supplierdetails?.address, 
                    VATRegistrationNo: supplierdetails?.vat_registration_no === null ? "" : supplierdetails?.vat_registration_no, 
                    RegistrationNo: supplierdetails?.registration_no === null ? "" : supplierdetails?.registration_no, 
                    Title: supplierdetails?.title, 
                    WebSiteUrl: supplierdetails?.website 
                });
                setPhoneNumber({
                    ...PhoneNumber,
                    phone_code: supplierdetails?.phone_code === null ? "" : supplierdetails?.phone_code,
                    phone: supplierdetails?.phone === null ? "" : supplierdetails?.phone
                });
                if(supplierdetails?.contacts !== undefined) {
                    setAddMoreContactState(supplierdetails?.contacts);
                };
            };
        }
    }, [ supplierdetails ]);

    // const GetContactDetails = AddMoreContactState.filter((elm) => (EmailRegex.test(elm?.email) === true && elm?.phone?.toString()?.length === 10));
    const GetContactDetails = AddMoreContactState.filter((elm) => {
        const CountryStates = getRegionCodeForCountryCode(PhoneNumber?.phone_code);
        const CheckMobileNumber = parsePhoneNumber(elm?.phone?.toString(), { regionCode: CountryStates });
        if(EmailRegex.test(elm?.email) === true && CheckMobileNumber?.valid === true) {
            return elm;
        };
    });

    // mobile number validation
    const CheckValidMobileNo = (number, code) => {
        const CountryStates = getRegionCodeForCountryCode(code);
        const CheckMobileNumber = parsePhoneNumber(number.toString(), { regionCode: CountryStates });
        if(number !== "") {
            if(CheckMobileNumber?.valid === false) {
                return(<div className="errormendatorr">
                    <i className="bi bi-x-circle-fill"></i>Please enter valid region mobile number
                </div>)
            };
        };
    };

    // delete multi contact
    const DeleteMultiContact = (data_) => {
        const DeleteMoreContactArray = [];
        AddMoreContactArray && AddMoreContactArray.filter((elm) => {
            if(elm?.id !== data_?.id) {
                return DeleteMoreContactArray.push(elm);
            }
        });
        setAddMoreContactState(DeleteMoreContactArray);
        if(data_?.supplier_id !== undefined) {
            let param = { 
                "transmit": "broadcast", 
                "url": "update_supplier",
                "request" : { 
                    "supplier_id": Params?.roomId,
                    "contact": {
                        "delete_contact": data_?.id
                    },
                    "DeviceId" : device_id
                }
            };
            wsSend_request(websocket, param);
        };
    };

    const CountryStates = getRegionCodeForCountryCode(PhoneNumber?.phone_code);
    const CheckMobileNumber = parsePhoneNumber(PhoneNumber?.phone?.toString(), { regionCode: CountryStates });

    // create supplier
    const CreateSupplierFnct = () => {
        if(Location === "edit-supplier") {
            if(SupplierName !== "" && CompanyName !== "" && Email === "" && PhoneNumber?.phone === "") {
                if(GetContactDetails.length > 0) {
                    let param = { 
                        "transmit": "broadcast", 
                        "url": "update_supplier",
                        "request" : { 
                            "supplier_id": Params?.roomId,
                            "name": SupplierName, 
                            "email": Email, 
                            "phone_code": PhoneNumber?.phone_code, 
                            "title": Title,
                            "website": WebSiteUrl,
                            "phone": PhoneNumber?.phone, 
                            "company" : CompanyName, 
                            "address" : Address, 
                            "vat_registration_no" : VATRegistrationNo, 
                            "registration_no" : RegistrationNo, 
                            "has_contact": true,
                            "DeviceId" : device_id
                        }
                    };
                    wsSend_request(websocket, param);
                    setTimeout(() => {
                        if(AddSupplierForPorduct === undefined) {
                            Navigate(SUPPLIER_MANAGEMENT_URL);
                        } else {
                            handleClose()
                        };
                        ClearForm()
                    }, 200);
                } else {
                    let param = { 
                        "transmit": "broadcast", 
                        "url": "update_supplier",
                        "request" : { 
                            "supplier_id": Params?.roomId,
                            "name": SupplierName, 
                            "email": Email, 
                            "phone_code": PhoneNumber?.phone_code, 
                            "title": Title,
                            "phone": PhoneNumber?.phone, 
                            "company" : CompanyName, 
                            "address" : Address, 
                            "website": WebSiteUrl,
                            "vat_registration_no" : VATRegistrationNo, 
                            "registration_no" : RegistrationNo, 
                            "has_contact": false,
                            "DeviceId" : device_id
                        }
                    };
                    wsSend_request(websocket, param);
                    setTimeout(() => {
                        if(AddSupplierForPorduct === undefined) {
                            Navigate(SUPPLIER_MANAGEMENT_URL);
                        } else {
                            handleClose()
                        };
                        ClearForm()
                    }, 200);
                };
            } else if(inputValue?.Email !== "") {
                if(EmailRegex.test(inputValue?.Email) === true) {
                    if(SupplierName !== "" && CompanyName !== "") {
                        if(GetContactDetails.length > 0) {
                            let param = { 
                                "transmit": "broadcast", 
                                "url": "update_supplier",
                                "request" : { 
                                    "supplier_id": Params?.roomId,
                                    "name": SupplierName, 
                                    "email": Email, 
                                    "phone_code": PhoneNumber?.phone_code, 
                                    "title": Title,
                                    "website": WebSiteUrl,
                                    "phone": PhoneNumber?.phone, 
                                    "company" : CompanyName, 
                                    "address" : Address, 
                                    "vat_registration_no" : VATRegistrationNo, 
                                    "registration_no" : RegistrationNo, 
                                    "has_contact": true,
                                    "DeviceId" : device_id
                                }
                            };
                            wsSend_request(websocket, param);
                            setTimeout(() => {
                                if(AddSupplierForPorduct === undefined) {
                                    Navigate(SUPPLIER_MANAGEMENT_URL);
                                } else {
                                    handleClose()
                                };
                                ClearForm()
                            }, 200);
                        } else {
                            let param = { 
                                "transmit": "broadcast", 
                                "url": "update_supplier",
                                "request" : { 
                                    "supplier_id": Params?.roomId,
                                    "name": SupplierName, 
                                    "email": Email, 
                                    "phone_code": PhoneNumber?.phone_code, 
                                    "phone": PhoneNumber?.phone, 
                                    "company" : CompanyName, 
                                    "address" : Address, 
                                    "website": WebSiteUrl,
                                    "vat_registration_no" : VATRegistrationNo, 
                                    "registration_no" : RegistrationNo, 
                                    "has_contact": false,
                                    "DeviceId" : device_id
                                }
                            };
                            wsSend_request(websocket, param);
                            setTimeout(() => {
                                if(AddSupplierForPorduct === undefined) {
                                    Navigate(SUPPLIER_MANAGEMENT_URL);
                                } else {
                                    handleClose()
                                };
                                ClearForm()
                            }, 200);
                        };
                    }
                } else {
                    CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
                }
            } else if(PhoneNumber?.phone !== "") {
                if(CheckMobileNumber?.valid === true) {
                    if(SupplierName !== "" && CompanyName !== "") {
                        if(GetContactDetails.length > 0) {
                            let param = { 
                                "transmit": "broadcast", 
                                "url": "update_supplier",
                                "request" : { 
                                    "supplier_id": Params?.roomId,
                                    "name": SupplierName, 
                                    "email": Email, 
                                    "phone_code": PhoneNumber?.phone_code, 
                                    "title": Title,
                                    "website": WebSiteUrl,
                                    "phone": PhoneNumber?.phone, 
                                    "company" : CompanyName, 
                                    "address" : Address, 
                                    "vat_registration_no" : VATRegistrationNo, 
                                    "registration_no" : RegistrationNo, 
                                    "has_contact": true,
                                    "DeviceId" : device_id
                                }
                            };
                            wsSend_request(websocket, param);
                            setTimeout(() => {
                                if(AddSupplierForPorduct === undefined) {
                                    Navigate(SUPPLIER_MANAGEMENT_URL);
                                } else {
                                    handleClose()
                                };
                                ClearForm()
                            }, 200);
                        } else {
                            let param = { 
                                "transmit": "broadcast", 
                                "url": "update_supplier",
                                "request" : { 
                                    "supplier_id": Params?.roomId,
                                    "name": SupplierName, 
                                    "email": Email, 
                                    "phone_code": PhoneNumber?.phone_code, 
                                    "phone": PhoneNumber?.phone, 
                                    "company" : CompanyName, 
                                    "address" : Address, 
                                    "website": WebSiteUrl,
                                    "vat_registration_no" : VATRegistrationNo, 
                                    "registration_no" : RegistrationNo, 
                                    "has_contact": false,
                                    "DeviceId" : device_id
                                }
                            };
                            wsSend_request(websocket, param);
                            setTimeout(() => {
                                if(AddSupplierForPorduct === undefined) {
                                    Navigate(SUPPLIER_MANAGEMENT_URL);
                                } else {
                                    handleClose()
                                };
                                ClearForm()
                            }, 200);
                        };
                    }
                } else {
                    CheckValid(PhoneNumber, {type: 'phone', error: setErrorPhone });
                }
            } else {
                CheckValid(SupplierName, {type: 'SupplierName', error: setErrorSupplierName});
                CheckValid(CompanyName, {type: 'CompanyName', error: setErrorCompanyName});
            };
        } 
    };


    const AddNewMultiContact = GetContactDetails.filter((elm) => elm?.supplier_id === undefined)
    // create new suppier contacts
    const CreateSupplierContactFnct = () => {
        if(AddNewMultiContact?.length > 0) {
            let param = { 
                "transmit": "broadcast", 
                "url": "update_supplier",
                "request" : { 
                    "supplier_id": Params?.roomId,
                    "contact": AddNewMultiContact,
                    "DeviceId" : device_id
                }
            };
            wsSend_request(websocket, param);
            setTimeout(() => {
                if(AddSupplierForPorduct === undefined) {
                    Navigate(SUPPLIER_MANAGEMENT_URL);
                } else {
                    handleClose()
                };
                ClearForm()
            }, 200);
        }
    };

    // edit multiple supplier contact
    const editMultiContact = (elm_) => {
        let param = { 
            "transmit": "broadcast", 
            "url": "update_supplier",
            "request" : { 
                "supplier_id": Params?.roomId,
                "contact": elm_,
                "DeviceId" : device_id
            }
        };
        wsSend_request(websocket, param);
        setTimeout(() => {
            if(AddSupplierForPorduct === undefined) {
                Navigate(SUPPLIER_MANAGEMENT_URL);
            } else {
                handleClose()
            };
            ClearForm()
        }, 200);
    };

    // clear form
    const ClearForm = () => {
        setInputValue({...inputValue, SupplierName: "", CompanyName: "", Email: "", Address: "", VATRegistrationNo: "", RegistrationNo: "" });
        setErrorSupplierName("")
        setErrorCompanyName("");
        setErrorEmail("");
        setErrorPhone("");
        setErrorAddress("");
        setErrorVATRegistrationNo("");
        setErrorRegistrationNo("");
        setPhoneNumber({...PhoneNumber, phone_code: "1", phone: ""});
    };

    // add multi contact 
    const AddMultiContactDat = () => {
        return(<div className="row" style={{ margin: "0 auto" }}>
            <div className="col-md-12">
                <div className="addmoreContact">
                    <div className="addcontact_head">
                        <button className="btn add_more_contact" onClick={() => AddMoreContact()}>
                            <i className="bi bi-plus-circle"></i> Add Contact Person
                        </button>
                    </div>
                    <div className="addcontact_wrapper">
                        {AddMoreContactState.map((elm, index) => {
                            return(<div className="row" key={index}>
                                <div className="col-md-11">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Name</label>
                                                <div className="formInput">
                                                    <input type="text" className="form-control" value={elm?.name} placeholder="Enter Name" onChange={(e) => GetContactUpdate(e, elm?.id, "Name")} onKeyDown={EmptySpaceFieldValid} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <div className="formInput">
                                                    <input type="text" className="form-control" value={elm?.email} placeholder="Enter Email" onChange={(e) => GetContactUpdate(e, elm?.id, "Email")} onKeyDown={EmptySpaceFieldValid} />
                                                </div>
                                                {(elm?.email.length > 1 && EmailRegex.test(elm?.email) === false) && (<div className="errormendatorr"><i className="bi bi-x-circle-fill"></i>Please enter valid email address</div>)}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Title</label>
                                                <div className="formInput">
                                                    <input type="text" className="form-control" value={elm?.title} placeholder="Enter Title" onChange={(e) => GetContactUpdate(e, elm?.id, "Title")} onKeyDown={EmptySpaceFieldValid} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Phone Number</label>
                                                <div className="selectNoInputField multiPhoneCodeset">
                                                    <Dropdown className="inputBoxSelect">
                                                        <Dropdown.Toggle className="selectEmojis" variant="success" id="dropdown-basic">
                                                            {/* {elm?.phone_flag !== "" && <img src={FlagsUrl + getCountryName(elm) + ".svg"} className="flagsimg" alt="call image" />} */}
                                                            <div className="countselet">+{elm?.phone_code}</div>
                                                            <img src={CaretDown} className="arrowdownimg" alt="caret down" />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="flagsDropdown">
                                                            <div className="flagsDropdownMain">
                                                                {Countries.filter((elm) => {
                                                                    if(CountrySelect === "") {
                                                                        return elm;
                                                                    } else if(elm?.country_en.toLowerCase()?.includes(CountrySelect && CountrySelect?.toLowerCase())) {
                                                                        return elm;
                                                                    } 
                                                                }).map((flags, index) => {
                                                                    return(<Dropdown.Item key={index} onClick={() => SelectCountryCodeContactDetails(flags, elm?.id)}>
                                                                        <img src={FlagsUrl + flags.country_code + ".svg"} />
                                                                        <span>{flags?.country_en} (+{flags.phone_code})</span>
                                                                </Dropdown.Item>)
                                                                })}
                                                            </div>
                                                            <input type="text" value={CountrySelect} onChange={(e) => setCountrySelect(e.target.value)} placeholder="Search Country..." autoComplete="off" />
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <input type="number" className="inputBoxs" value={elm?.phone} placeholder="Enter Phone Number" onChange={(e) => GetContactUpdate(e, elm?.id, "Phone")} maxLength={10} onKeyDown={EmptySpaceFieldValid} />
                                                </div>
                                                {CheckValidMobileNo(elm.phone, elm?.phone_code)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1" style={{ padding: "0px" }}>
                                    <div className="form-group">
                                        <label>&nbsp;</label>
                                        <div className="add_morefunction">
                                            {elm?.added_by !== undefined && (<button type="button" className="btn btn_successbtn" onClick={() => editMultiContact(elm)}>
                                                <i className="bi bi-pencil-square"></i>
                                            </button>)}
                                            <button type="button" className="btn btn_deletebtn" onClick={() => DeleteMultiContact(elm)}>
                                                <i className="bi bi-trash3-fill"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        })}
                    </div>
                </div>
                {AddNewMultiContact?.length > 0 && (<div className="col-md-12">
                    <div className="form-group-btn"  style={{ textAlign: "right" }}>
                        <button className="btn addbtncmn" onClick={() => CreateSupplierContactFnct()}>Save</button>
                    </div>
                </div>)}
            </div>
        </div>)
    };

    return(<React.Fragment>
        <ul className="tablehead_tabs">
            <li className={selectTab === "Add_Supplier" ? "active" : ""} onClick={() => setSelectTab("Add_Supplier")}>Edit</li>
            <li className={selectTab === "Add_New_Supplier_Contact" ? "active" : ""} onClick={() => setSelectTab("Add_New_Supplier_Contact")}>Edit Supplier Contact</li>
            <BackButton targeturl={SUPPLIER_MANAGEMENT_URL} title={"Back"} />
        </ul>
        <div className="dashboardTablesTbs">
            {selectTab === "Add_Supplier" ? (<section className="dashboard_wrapper_form" >
                <Scrollbars 
                    style={{ height: "calc(100vh - 150px)"}} 
                    className="ScrollbarsWrapper"
                    renderView={props => <div {...props} className="view"/>}
                >
                    <div className="scrollform">
                        <div className="row" style={{ margin: "0 auto" }}>
                            <div className="col-md-4">
                                <InputField
                                    type="text"
                                    value={SupplierName}
                                    className={errorSupplierName !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Supplier Name"
                                    label="Supplier Name <span class='redmednstar'>*</span>"
                                    name="SupplierName"
                                    onChange={handleChange}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'SupplierName', error: setErrorSupplierName})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorSupplierName}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    type="text"
                                    value={CompanyName}
                                    className={errorCompanyName !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Company Name"
                                    label="Company Name <span class='redmednstar'>*</span>"
                                    name="CompanyName"
                                    onChange={handleChange}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'CompanyName', error: setErrorCompanyName})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorCompanyName}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    type="text"
                                    value={Title}
                                    className={errorTitle !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Title"
                                    label="Title"
                                    name="Title"
                                    onChange={handleChange}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'Title', error: setErrorTitle})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorTitle}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={Email}
                                    className={errorEmail !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Email"
                                    label="Email address"
                                    name="Email"
                                    onChange={handleChange}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'email', error: setErrorEmail})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorEmail}
                                />
                            </div>
                            <div className="col-md-4">
                                <CountrySelectInput phone={PhoneNumber} setPhone={setPhoneNumber} CheckValid={CheckValid} errorPhone={errorPhone} setErrorPhone={setErrorPhone} />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={WebSiteUrl}
                                    className={errorWebSiteUrl !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter WebSite"
                                    label="Website"
                                    name="WebSiteUrl"
                                    onChange={handleChange}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'WebSiteUrl', error: setErrorWebSiteUrl})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorWebSiteUrl}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={VATRegistrationNo}
                                    className={errorVATRegistrationNo !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter VAT Registration No."
                                    label="VAT Registration No."
                                    name="VATRegistrationNo"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'VATRegistrationNo', error: setErrorVATRegistrationNo})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorVATRegistrationNo}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={RegistrationNo}
                                    className={errorRegistrationNo !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Registration No."
                                    label="Registration No."
                                    name="RegistrationNo"
                                    onChange={handleChange}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'RegistrationNo', error: setErrorRegistrationNo})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorRegistrationNo}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={Address}
                                    className={errorAddress !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Address"
                                    label="Address"
                                    name="Address"
                                    onChange={handleChange}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'Address', error: setErrorAddress})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorAddress}
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group-btn"  style={{ textAlign: "right" }}>
                                <button className="btn addbtncmn" onClick={() => CreateSupplierFnct()}>Add</button>
                            </div>
                        </div>
                    </div>
                </Scrollbars>
            </section>) : (<section className="dashboard_wrapper_form" >
                <Scrollbars 
                    style={{ height: "calc(100vh - 150px)"}} 
                    className="ScrollbarsWrapper"
                    renderView={props => <div {...props} className="view"/>}
                >
                    <div className="scrollform">
                        {AddMultiContactDat()}
                    </div>
                </Scrollbars>
            </section>)}
        </div>
        {/* loader start here */}
        {loaderMain && (<CommonLoader />)}
    </React.Fragment>)
}

export default EditSupplier;