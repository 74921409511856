/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import BackButton from "../Common/BackButton";
import { SUPPLIER_MANAGEMENT_URL } from "../Shared/constant";
import { useParams } from "react-router-dom";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { useSelector } from "react-redux";
import moment from "moment";
import Scrollbars from "react-custom-scrollbars-2";
import CommonLoader from "../Common/CommonLoader";

const ViewSupplier = () => {
    const { websocket } = useContext(WebSocketContext);
    const { supplierdetails, userListIdWise, device_id, loaderMain } = useSelector((state) => state.adminReducers);
    const Params = useParams();
    const [ selectTab, setSelectTab ] = useState("View");

    // get supplier details
    useEffect(() => {
        if(Params?.roomId) {
            let param = { 
                "transmit": "broadcast", 
                "url": "supplier_detail",
                "request" : { 
                    "supplier_id" : Params?.roomId,
                    "DeviceId" : device_id
                }
            };
            wsSend_request(websocket, param);
        };
    }, [ Params?.roomId ]);


    return(<section className="dashboard_wrapper">
    <ul className="tablehead_tabs">
        <li className={selectTab === "View" ? "active" : ""} onClick={() => setSelectTab("View")}>View</li>
        {/* <li className={selectTab === "Document" ? "active" : ""} onClick={() => setSelectTab("Document")}>Document</li> */}
        {selectTab === "Document" && (<button type="button" className="btn uploadbtn"><i class="bi bi-upload"></i> Upload Document</button>)}
        <BackButton targeturl={SUPPLIER_MANAGEMENT_URL} title={"Back"} />
    </ul>
    <div className="dashboardTablesTbs">
        {selectTab === "View" ? (<div className="view_details">
            <Scrollbars 
                style={{ height: "calc(100% - 38px)"}} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="row" >
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>First Name</label>
                            <div className="text-show">
                                {(supplierdetails?.name === "" || supplierdetails?.name === null) ? "-" : supplierdetails?.name}	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Email</label>
                            <div className="text-show">
                                {(supplierdetails?.email === "" || supplierdetails?.email === null) ? "-" : supplierdetails?.email}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Company Name	</label>
                            <div className="text-show">
                                {(supplierdetails?.company === "" || supplierdetails?.company === null) ? "-" : supplierdetails?.company}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Phone</label>
                            <div className="text-show">
                                {supplierdetails?.phone === null ? "-" : "+" + supplierdetails?.phone_code + " " + supplierdetails?.phone}		
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Title</label>
                            <div className="text-show">
                                {(supplierdetails?.title === "" || supplierdetails?.title === null) ? "-" : supplierdetails?.title}		
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Website</label>
                            <div className="text-show">
                                {(supplierdetails?.website === "" || supplierdetails?.website === null) ? "-" : supplierdetails?.website}
                                {/* <a href={supplierdetails?.website} target="__blank">{supplierdetails?.website}</a> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Added By</label>
                            <div className="text-show">
                                {userListIdWise[supplierdetails.added_by]?.email}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Address</label>
                            <div className="text-show">
                                {(supplierdetails?.address === "" || supplierdetails?.address === null) ? "-" : supplierdetails?.address}	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>VAT Registration No</label>
                            <div className="text-show">
                                {supplierdetails?.vat_registration_no === null ? "-" : supplierdetails?.vat_registration_no}	
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Registration No	</label>
                            <div className="text-show">
                                {supplierdetails?.registration_no === null ? "-" : supplierdetails?.registration_no}	
                            </div>
                        </div>
                    </div>
                    {supplierdetails?.contacts?.length > 0 && (<div className="col-md-12">
                        <div className="form-group">
                            <label>Contact Persons	</label>
                            <div className="tableviewdata">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Title</th>
                                            <th>Phone</th>
                                            <th>Created At</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {supplierdetails?.contacts?.map((elm, index) => {
                                            return(<tr key={index}>
                                                <td>{elm?.name === "" ? "-" : elm?.name}</td>
                                                <td>{elm?.email}</td>
                                                <td>{elm?.title === "" ? "-" : elm?.title}</td>
                                                <td>{"+" + elm?.phone_code + "-" + elm?.phone}</td>
                                                <td>{moment(elm.created_at).format('LL')}</td>
                                            </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            </div>
                            
                        </div>
                    </div>)}
                </div>
            </Scrollbars>
        </div>) : (<div className="document_view">
            <div className="row">
                <div className="col-md-4">
                    <div className="documnt_column">
                        <i className="bi bi-filetype-pdf"></i>
                        <div className="file_details">
                            <h4>Sample.pdf</h4>
                            <span>122.00 KB</span>
                        </div>
                        <button type="button" className="download_file">
                            <i className="bi bi-download"></i>
                        </button>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="documnt_column">
                        <i className="bi bi-filetype-docx"></i>
                        <div className="file_details">
                            <h4>Sample.docx</h4>
                            <span>122.00 KB</span>
                        </div>
                        <button type="button" className="download_file">
                            <i className="bi bi-download"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>)}
    </div>
    {/* loader start here */}
    {loaderMain && (<CommonLoader />)}
</section>)
}

export default ViewSupplier;