// useWebSocket.js
import { useEffect } from 'react';
import { ws_response } from './ws/ws_response';

const useWebSocket = (url, functions, Dispatch, accessToken) => {
  useEffect(() => {
    const socket = new WebSocket(url);
    console.log("url==========>", url, accessToken)
    functions(socket);
    // Set up event handlers
    socket.onmessage = function (evt) {
        Dispatch(ws_response({evt:evt, ws:socket }));
    };
    socket.onclose = (event) => {
        console.log("websocket close")
        // if(accessToken !== "") {
        //     window.location.reload();
        // }
    };
    return () => {
      socket.close();
    };
  }, [url]);
};

export default useWebSocket;