/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import CommonToggle from "../Common/CommonToggle";
import moment from "moment";
import CommonModel from "../Common/CommonModel";
import { useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonPagination from "../Common/CommonPagination";

const Model = () => {
    const { websocket } = useContext(WebSocketContext);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const { selfInfo, modelList, brandlistall, userListIdWise } = useSelector((state) => state.adminReducers);

    // get product list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "product_brand_list",
            "request" : { 
                "filter" : "all", 
                "limit" : 5000, 
                "page_no" : 1, 
                "search" : "" 
            }
        };
        wsSend_request(websocket, param);
    }, [ ]);

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };
    
    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // brand name get
    const BrandNameGet = (brand_id) => {
        return brandlistall?.filter((elm) => brand_id === elm?.id)[0]?.name;
    };

    // get model list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "product_model_list",
            "request" : { 
                "filter" : userType, 
                "limit" : userLimit, 
                "page_no" : currentPage, 
                "search" : userSearch.trim()
            }
        };
        wsSend_request(websocket, param);
    }, [ userSearch, userType, userLimit, currentPage ]);

    // add new model
    const AddModelFnct = () => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Create Model",
            subtitle: "Create Model",
            description: ``,
            modalType: "form",
            button: "Yes",
        });
    };

    // edit model
    const EditModel = (data_) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Edit Model",
            subtitle: "Edit Model",
            description: ``,
            modalType: "form",
            button: "Save",
            Data: data_
        });
    };

    // allow permission for user
    const AllowPermissGet = (data_, type, booleancheck) => {
        if(type === "is_active") {
            setShowModal({
                ...showModal,
                open: !showModal?.open,
                title: "Are you sure?",
                subtitle: "Active Model",
                description: `Do you really want to ${booleancheck ? "deactivate" : "active"} to <h5>${data_?.name}</h5>`,
                modalType: "not form",
                button: "Yes",
                Data: data_
            });
        };
    }; 

    // delete model
    const DeleteModelFnct = (data_) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Delete Model",
            subtitle: "Delete Model",
            description: `Do you really want to delete <h5>${data_?.name}</h5>`,
            modalType: "not form",
            button: "Yes",
            Data: data_
        });
    };

    return(<section className="dashboard_wrapper">
        <DataTableFilter 
            filterType={"Model (name)"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.is_admin || (selfInfo?.permissions?.includes("add-model")))}
            ButtonSet={{
                buttontitle: "Add Model",
                function: AddModelFnct
            }}
        />
        <div className="tableContent" style={{ height: window.screen.width > 540 ? "calc(100vh - 208px)" : "calc(100vh - 190px)"}}>
            <Scrollbars 
                style={{ height: modelList?.pagination?.total_records > 10  ? (window.screen.width < 767 ? "calc(100% - 10px)" : "calc(100% - 38px)") : "calc(100% - 0px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Model Name</th>
                                <th>Brand Name</th>
                                {selfInfo?.is_admin && (<th>Added By</th>)}
                                {(selfInfo?.is_admin || selfInfo?.permissions?.includes("update-model")) && (<th>Active</th>)}
                                <th>Created at</th>
                                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("update-model") || selfInfo?.permissions?.includes("delete-model"))) && (<th>Action</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {modelList && modelList?.list?.map((user, index) => {
                                return(<React.Fragment key={index}>
                                    <tr>
                                        <td>{user?.name}</td>
                                        <td>{BrandNameGet(user?.brand_id) === undefined ? "-" : BrandNameGet(user?.brand_id)}</td>
                                        {selfInfo?.is_admin && (<td>{userListIdWise[user?.added_by]?.email === undefined ? (<span className="batch_code">User Deleted</span>) : userListIdWise[user?.added_by]?.email}</td>)}
                                        {(selfInfo?.is_admin || selfInfo?.permissions?.includes("update-model")) && (<td>
                                            <CommonToggle 
                                                valueToggle={user?.is_active}
                                                setValueToggle={() => AllowPermissGet(user, "is_active", user?.is_active)}
                                                name={""}
                                            />
                                        </td>)}
                                        <td>{moment(user.created_at).format('LL')}</td>
                                        {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("update-model") || selfInfo?.permissions?.includes("delete-model"))) && (<td className="respoactionSupplier">
                                            {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("update-model"))) && (<button type="button" className="btn actiontblebtn" onClick={() => EditModel(user)}>
                                                <i className="bi bi-pencil-square"></i>
                                            </button>)}
                                            {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("delete-model"))) && (<button type="button" className="btn actiontblebtn" onClick={() => DeleteModelFnct(user)}>
                                                    <i className="bi bi-trash-fill" style={{ color: "red" }}></i>
                                            </button>)}
                                        </td>)}
                                    </tr>
                                </React.Fragment>)
                            })}
                            {modelList && modelList?.list?.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={8}>Model Not Found !</td></tr>}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>
            {/* pagination */}
            {modelList?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={modelList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        {showModal?.open && (<CommonModel setUserSearch={setUserSearch} setCurrentPage={setCurrentPage} showModal={showModal} setShowModal={setShowModal} />)}
</section>)
}

export default Model;