/* eslint-disable */
import React, { useState, useEffect } from "react";
import Model from "./Model";
import Brands from "./Brands";
import { useDispatch, useSelector } from "react-redux";
import { getUserListIdWise } from "../../redux/actions/adminActions";
import GetUserListAPI from "../../Api/GetUserListAPI";
import CommonLogout from "../Common/CommonLogout";

const ModelBrand = () => {
    const Dispatch = useDispatch();
    const [ selectTab, setSelectTab ] = useState("Brand");
    const { accessToken, selfInfo } = useSelector((state) => state.adminReducers);

    // get user list
    useEffect(() => {
        if(accessToken && selfInfo?.is_admin) {
            GetUserList();
        };
    }, [ accessToken , selfInfo?.is_admin]);

    const GetUserList = async () => {
        const userList = await GetUserListAPI(accessToken, 0, "all" , 5000, 1);
        if(userList?.status === 200) {
            Dispatch(getUserListIdWise(userList.data));
        } else if(userList?.status === 403) {
            CommonLogout(accessToken, Dispatch);
            Dispatch(getUserListIdWise([]));
        } else {
            Dispatch(getUserListIdWise([]));
        };
    };

    return(<section className="dashboard_wrapper">
        <ul className="tablehead_tabs">
            <li className={selectTab === "Brand" ? "active" : ""} onClick={() => setSelectTab("Brand")}>Brand</li>
            <li className={selectTab === "Model" ? "active" : ""} onClick={() => setSelectTab("Model")}>Model</li>
        </ul>
        <div className="dashboardTablesTbs">
            {selectTab === "Model" ? (<Model />) : (<Brands />)}
        </div>
    </section>)
}

export default ModelBrand;