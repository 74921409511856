/* eslint-disable */
import React, { useEffect, useState, createContext } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../src/assets/css/style.css';
import '../src/assets/css/media.css';
import { LOGIN_URL, FORGOT_PASSWORD_URL, RESET_PASSWORD_URL, SETTINGS_MANAGEMENT_URL, EDIT_PRODUCT_MANAGEMENT_URL, EDIT_SUPPLIER_MANAGEMENT_URL, VIEW_PROFILE_URL, ADD_USER_URL, EDIT_USER_URL,VIEW_SUPPLIER_MANAGEMENT_URL, VIEW_PRODUCT_MANAGEMENT_URL, ADD_SUPPLIER_MANAGEMENT_URL, DASHBOARD_URL, USER_MANAGEMENT_URL, PRODUCT_MANAGEMENT_URL, ADD_PRODUCT_MANAGEMENT_URL, SUPPLIER_MANAGEMENT_URL, CART_MANAGEMENT_URL, MODEL_BRAND_MANAGEMENT_URL, VIEW_MODEL_BRANDS_MANAGEMENT_URL, CATEGORY_MANAGEMENT_URL } from './components/Shared/constant';
import GuestLayout from './components/Shared/GuestLayout';
import AuthLayout from './components/Shared/AuthLayout';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Dashboard from './components/Dashboard';
import UserManagement from './components/UserManagement';
import ProductMangement from './components/ProductMangement';
import Cart from './components/Cart';
import Supplier from './components/Supplier';
import AddEditProduct from './components/ProductMangement/AddEditProduct';
import ModelBrand from './components/ModelBrand';
import AddEditSupplier from './components/Supplier/AddEditSupplier';
import ViewProduct from './components/ProductMangement/ViewProduct';
import ViewSupplier from './components/Supplier/ViewSupplier';
import ViewBrandModels from './components/ModelBrand/ViewBrandModels';
import CategoryManagement from './components/CategoryManagement';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Profile from './components/Profile';
import { ws_response } from './Api/ws/ws_response';
import AddEditUser from './components/UserManagement/AddEditUser';
import GetUserListAPI from './Api/GetUserListAPI';
import { getUserListIdWise, clearRedux, checkLogin } from './redux/actions/adminActions';
import CommonLogout from './components/Common/CommonLogout';
import EditSupplier from './components/Supplier/EditSupplier';
import SettingsManagement from './components/SettingsManagement';
export const WebSocketContext = createContext();
import useWebSocket from './Api/UseWebsocket';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

function App(props) {
  const Dispatch = useDispatch();
  const { accessToken, is_login, ClearCaches_setww, loaderMain, selfInfo } = useSelector((state) => state.adminReducers);
  const [ GetWEBSocket, setGetWEBSocket ] = useState("");

  useWebSocket(`${process.env.REACT_APP_SERVICE_URL}/${accessToken}/`, setGetWEBSocket, Dispatch, accessToken);
  // // websocket connections
  // useEffect(() => {
  //   if(accessToken !== ""){
  //     WebSocketConnect();
  //   }
  // }, [ accessToken ]);

  // const WebSocketConnect = () => {
  //     var ws = new WebSocket(`${process.env.REACT_APP_SERVICE_URL}/${accessToken}/`);
  //     setGetWEBSocket(ws);
  //     ws.onopen = function (event) {
  //       console.log('connected');
  //       // window.location.reload();
  //     };
  //     ws.onmessage = function (evt) {
  //       Dispatch(ws_response({evt:evt, ws:ws }))
  //       const ws_onmessage = JSON.parse(evt.data);
  //       if(ws_onmessage?.url === "reconnect"){
  //         props?.callRender(`${process.env.REACT_APP_SERVICE_URL}/${accessToken}/`, accessToken)
  //       }
  //     }
  //     ws.onclose = async function (evt){
  //       console.log("websocket is closed",evt);
  //       setTimeout(function() {
  //         WebSocketConnect();
  //       }, 1000);
  //       if(navigator.onLine === false){
  //         props?.callRender(`${process.env.REACT_APP_SERVICE_URL}/${accessToken}/`, accessToken)
  //     }
  //     }
  //     window.addEventListener('online', () => {
  //       window.location.reload()
  //     });
  //     window.addEventListener("offline",()=>{
  //       window.location.reload()
  //     });
  //     return () => {
  //       ws.close()
  //     }
  // };

  // useEffect(() => {
  //   ws.onmessage = function (evt) {
  //     Dispatch(ws_response({evt:evt, ws:ws }))
  //     const ws_onmessage = JSON.parse(evt.data);
  //     if(ws_onmessage?.url === "reconnect"){
  //       props?.callRender(`${process.env.REACT_APP_SERVICE_URL}/${accessToken}/`, accessToken)
  //     }
  //   }
  //   ws.onclose = async function (evt){
  //     console.log("websocket is closed");
  //     // setTimeout(function() {
  //     //   WebSocketConnect();
  //     // }, 1000);
  //     if(navigator.onLine === false){
  //       props?.callRender(`${process.env.REACT_APP_SERVICE_URL}/${accessToken}/`, accessToken)
  //     }
  //   }
  //   window.addEventListener('online', () => {
  //     window.location.reload()
  //   });
  //   window.addEventListener("offline",()=>{
  //     window.location.reload()
  //   });
  //   return () => {
  //     ws.close()
  //   }
  // }, [])

  // get user list
  useEffect(() => {
    if(accessToken && selfInfo?.is_admin === true) {
      GetUserList();
    };
  }, [ accessToken ]);

  useEffect(() => {
    if(ClearCaches_setww === null || ClearCaches_setww === undefined) {
      Dispatch(clearRedux());
      localStorage.clear();
      Dispatch(checkLogin('no'));
    };
  }, [ ClearCaches_setww ]);

  const GetUserList = async () => {
      const userList = await GetUserListAPI(accessToken, 0, "all" , 5000, 1);
      if(userList?.status === 200) {
          Dispatch(getUserListIdWise(userList.data));
      } else if(userList?.status === 403) {
          CommonLogout(accessToken, Dispatch);
          Dispatch(getUserListIdWise([]));
      } else {
          Dispatch(getUserListIdWise([]));
      };
  };

  
  const RoutUrlSet = () => {
    if(accessToken === "" && is_login === "no") {
      return LOGIN_URL;
    } else {
      if(selfInfo?.is_admin || (selfInfo?.permissions?.includes("dashboard"))) {
        return DASHBOARD_URL;
      } else {
        if(selfInfo?.permissions !== null) {
          if(selfInfo?.permissions?.split(",")[0] === "user-list" || 
            selfInfo?.permissions?.split(",")[0] === "add-user" || 
            selfInfo?.permissions?.split(",")[0] === "update-user" ||
            selfInfo?.permissions?.split(",")[0] === "delete-user"
          ) {
              return USER_MANAGEMENT_URL;
          } else if(selfInfo?.permissions?.split(",")[0] === "product-list" || 
          selfInfo?.permissions?.split(",")[0] === "add-product" || 
          selfInfo?.permissions?.split(",")[0] === "update-product" ||
          selfInfo?.permissions?.split(",")[0] === "delete-product"
          ) {
              return PRODUCT_MANAGEMENT_URL;
          } else if(selfInfo?.permissions?.split(",")[0] === "add-cart" || 
          selfInfo?.permissions?.split(",")[0] === "export-cart" || 
          selfInfo?.permissions?.split(",")[0] === "cart-list"
          ) {
              return CART_MANAGEMENT_URL;
          } else if(selfInfo?.permissions?.split(",")[0] === "add-supplier" || 
          selfInfo?.permissions?.split(",")[0] === "update-supplier" || 
          selfInfo?.permissions?.split(",")[0] === "delete-supplier" ||
          selfInfo?.permissions?.split(",")[0] === "supplier-list"
          ) {
              return SUPPLIER_MANAGEMENT_URL;
          }else if(selfInfo?.permissions?.split(",")[0] === "model-list" || 
          selfInfo?.permissions?.split(",")[0] === "add-model" || 
          selfInfo?.permissions?.split(",")[0] === "update-model" ||
          selfInfo?.permissions?.split(",")[0] === "delete-model" ||
          selfInfo?.permissions?.split(",")[0] === "brand-list" ||
          selfInfo?.permissions?.split(",")[0] === "add-brand" ||
          selfInfo?.permissions?.split(",")[0] === "update-brand" ||
          selfInfo?.permissions?.split(",")[0] === "delete-brand"
          ) {
              return MODEL_BRAND_MANAGEMENT_URL;
          }else if(selfInfo?.permissions?.split(",")[0] === "product-setting-list" || 
          selfInfo?.permissions?.split(",")[0] === "add-product-setting" ||
          selfInfo?.permissions?.split(",")[0] === "update-product-setting" ||
          selfInfo?.permissions?.split(",")[0] === "delete-product-setting"
          ) {
              return SETTINGS_MANAGEMENT_URL;
          }
        } 
      }
    }
  };

  return (<WebSocketContext.Provider value={{websocket:GetWEBSocket}}>
    <div className="wrapper">
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<Navigate replace to={RoutUrlSet()}/>} />
            <Route path="/" element={(accessToken === "" && is_login === "no") ? <GuestLayout><Login /></GuestLayout> : <Navigate replace to={RoutUrlSet()}/>} >
                <Route path={LOGIN_URL} element={(accessToken === "" && is_login === "no") ? <GuestLayout><Login /></GuestLayout> : <Navigate replace to={RoutUrlSet()}/>} />
            </Route>
            <Route path={FORGOT_PASSWORD_URL} element={<GuestLayout><ForgotPassword /></GuestLayout>} />
            <Route path={`${RESET_PASSWORD_URL}/:roomId`} element={<GuestLayout><ResetPassword /></GuestLayout>} />
            <Route path={DASHBOARD_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><Dashboard /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={USER_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><UserManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={ADD_USER_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><AddEditUser /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={`${EDIT_USER_URL}/:roomId`} element={<AuthLayout><AddEditUser /></AuthLayout>} />
            <Route path={VIEW_PROFILE_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><Profile /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={PRODUCT_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><ProductMangement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={`${VIEW_PRODUCT_MANAGEMENT_URL}/:roomId`} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><ViewProduct /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={ADD_PRODUCT_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><AddEditProduct /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={`${EDIT_PRODUCT_MANAGEMENT_URL}/:roomId`} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><AddEditProduct /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={CART_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><Cart /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={SUPPLIER_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><Supplier /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={ADD_SUPPLIER_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><AddEditSupplier /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={`${VIEW_SUPPLIER_MANAGEMENT_URL}/:roomId`} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><ViewSupplier /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={`${EDIT_SUPPLIER_MANAGEMENT_URL}/:roomId`} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><EditSupplier /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={MODEL_BRAND_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><ModelBrand /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={`${VIEW_MODEL_BRANDS_MANAGEMENT_URL}/:roomId`} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><ViewBrandModels /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={CATEGORY_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><CategoryManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={SETTINGS_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><SettingsManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
          </Routes>
        </BrowserRouter>
    </div>
    <ToastContainer />
    </WebSocketContext.Provider>);
}

export default App;
