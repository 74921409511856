/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { SUPPLIER_MANAGEMENT_URL } from "../Shared/constant";
import BackButton from "../Common/BackButton";
import InputField from "../Common/InputField";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { Dropdown } from 'react-bootstrap';
import CountrySelectInput from "../Common/CountrySelectInput/CountryMobileInput";
import { Scrollbars } from 'react-custom-scrollbars-2';
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import Countries from "../Common/CountrySelectInput/CountryCodes.json";
import CaretDown from "../../assets/img/caret-down.svg";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { parsePhoneNumber, getRegionCodeForCountryCode } from "awesome-phonenumber";

const AddEditSupplier = (props) => {
    const { AddSupplierForPorduct, handleClose } = props;
    const { device_id } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Navigate = useNavigate();
    const Params = useParams();
    const Location = useLocation()?.pathname?.split("/")[1];
    const [ inputValue, setInputValue ] = useState({ SupplierName : "", CompanyName: "", Email: "", Address: "", VATRegistrationNo: "", RegistrationNo: "", Title: "", WebSiteUrl: "" });
    const { Email, SupplierName, Address, CompanyName, VATRegistrationNo, RegistrationNo, Title, WebSiteUrl } = inputValue;
    const [ PhoneNumber, setPhoneNumber ] = useState({
        phone_code: "1",
        phone: ""
    });
    const [ CountrySelect, setCountrySelect ] = useState("");
    const FlagsUrl = "https://purecatamphetamine.github.io/country-flag-icons/3x2/";
 
    // Regex
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line

    // error message
    const [ errorEmail, setErrorEmail ] = useState("");
    const [ errorSupplierName, setErrorSupplierName ] = useState("");
    const [ errorAddress, setErrorAddress ] = useState("");
    const [ errorPhone, setErrorPhone ] = useState("");
    const [ errorCompanyName, setErrorCompanyName ] = useState("");
    const [ errorVATRegistrationNo, setErrorVATRegistrationNo ] = useState("");
    const [ errorRegistrationNo, setErrorRegistrationNo ] = useState("");
    const [ errorWebSiteUrl, setErrorWebSiteUrl ] = useState("");
    const [ errorTitle, setErrorTitle ] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // get supplier details
    useEffect(() => {
        if(Location === "edit-supplier") {
            if(Params?.roomId) {
                let param = { 
                    "transmit": "broadcast", 
                    "url": "supplier_detail",
                    "request" : { 
                        "supplier_id" : Params?.roomId
                    }
                };
                wsSend_request(websocket, param);
            };
        }
    }, [ Params?.roomId, Location ]);

    // add more contact function
    const [ AddMoreContactState, setAddMoreContactState ] = useState([]);
    let AddMoreContactArray = [...AddMoreContactState];

    const AddMoreContact = () => {
        var MoreContactObj = { 
            name: "", 
            title: "", 
            email: "", 
            phone_code: "1", 
            phone_flag: "US",
            phone: "",
            id: (Math.random() + 1).toString(36).substring(7),
        }
        AddMoreContactArray.push(MoreContactObj);
        setAddMoreContactState(AddMoreContactArray);
    };

    // get email name and phone, title 
    const GetContactUpdate = (e, id, url) => {
        const NewUpdatedContactArray = AddMoreContactArray.map((elm) => {
            if(elm?.id === id) {
                if(url === "Name") {
                    return{
                        ...elm,
                        name: url === "Name" && (e.target?.value === "" ? "" : e.target?.value)
                    }
                } else if(url === "Title") {
                    return{
                        ...elm,
                        title: url === "Title" && (e.target?.value === "" ? "" : e.target?.value)
                    }
                } else if(url === "Email") {
                    return{
                        ...elm,
                        email: url === "Email" && (e.target?.value === "" ? "" : e.target?.value)
                    }
                } else {
                    return{
                        ...elm,
                        phone: url === "Phone" && (e.target?.value === "" ? "" : e.target?.value),  
                    }
                }
            } else 
            return elm;
        });
        setAddMoreContactState(NewUpdatedContactArray);
    };

    // select country and flag select function
    const SelectCountryCodeContactDetails = (flag, index) => {
        const UpdatePhoneArray = AddMoreContactArray.map((elm) => {
            if(elm?.id === index) {
                return{
                    ...elm,
                    phone_flag:  flag?.country_code,
                    phone_code: flag?.phone_code,
                }
            } else 
            return elm;
        })
        setAddMoreContactState(UpdatePhoneArray);
    };

    // delete multi contact
    const DeleteMultiContact = (delet_id) => {
        const DeleteMoreContactArray = [];
        AddMoreContactArray && AddMoreContactArray.filter((elm) => {
            if(elm?.id !== delet_id) {
                return DeleteMoreContactArray.push(elm);
            }
        });
        setAddMoreContactState(DeleteMoreContactArray);
    };

    
    const GetContactDetails = AddMoreContactState.filter((elm) => {
        const CountryStates = getRegionCodeForCountryCode(PhoneNumber?.phone_code);
        const CheckMobileNumber = parsePhoneNumber(elm?.phone?.toString(), { regionCode: CountryStates });
        if(EmailRegex.test(elm?.email) === true && CheckMobileNumber?.valid === true) {
            return elm;
        };
    });


    // mobile number validation
    const CheckValidMobileNo = (number, code) => {
        const CountryStates = getRegionCodeForCountryCode(code);
        const CheckMobileNumber = parsePhoneNumber(number?.toString(), { regionCode: CountryStates });
        if(number !== "") {
            if(CheckMobileNumber?.valid === false) {
                return(<div className="errormendatorr">
                    <i className="bi bi-x-circle-fill"></i>Please enter valid region mobile number
                </div>)
            };
        };
    };

    const CountryStates = getRegionCodeForCountryCode(PhoneNumber?.phone_code);
    const CheckMobileNumber = parsePhoneNumber(PhoneNumber?.phone?.toString(), { regionCode: CountryStates });
    // create supplier
    const CreateSupplierFnct = () => {
        if(SupplierName !== "" && CompanyName !== "" && Email === "" && PhoneNumber?.phone === "") {
            if(GetContactDetails.length > 0) {
                let paramRequest = { 
                    "name": SupplierName, 
                    "email": Email, 
                    "phone": PhoneNumber?.phone, 
                    "title": Title,
                    "website": WebSiteUrl,
                    "company" : CompanyName, 
                    "address" : Address, 
                    "vat_registration_no" : VATRegistrationNo, 
                    "registration_no" : RegistrationNo, 
                    "has_contact": true,
                    "contact": GetContactDetails,
                    "DeviceId" : device_id
                };
                const paramfilter = Object.entries(paramRequest).filter((elm) => {
                    if(elm[1] !== "" || elm[0]==="email") {
                        return elm;
                    }
                });
                let param = { 
                    "transmit": "broadcast", 
                    "url": "add_supplier",
                    "request" : Object.fromEntries(paramfilter)
                };
                wsSend_request(websocket, param);
                setTimeout(() => {
                    if(AddSupplierForPorduct === undefined) {
                        Navigate(SUPPLIER_MANAGEMENT_URL);
                    } else {
                        handleClose()
                    };
                    ClearForm()
                }, 200);
            } else {
                let paramRequest = { 
                    "name": SupplierName, 
                    "email": Email, 
                    // "phone_code": PhoneNumber?.phone_code, 
                    "title": Title,
                    // "phone": PhoneNumber?.phone, 
                    "company" : CompanyName, 
                    "address" : Address, 
                    "website": WebSiteUrl,
                    "vat_registration_no" : VATRegistrationNo, 
                    "registration_no" : RegistrationNo, 
                    "has_contact": false,
                    "DeviceId" : device_id
                };
                const paramfilter = Object.entries(paramRequest).filter((elm) => {
                    if(elm[1] !== "" || elm[0]==="email") {
                        return elm;
                    }
                });
                let param = { 
                    "transmit": "broadcast", 
                    "url": "add_supplier",
                    "request" : Object.fromEntries(paramfilter)
                };
                wsSend_request(websocket, param);
                setTimeout(() => {
                    if(AddSupplierForPorduct === undefined) {
                        Navigate(SUPPLIER_MANAGEMENT_URL);
                    } else {
                        handleClose()
                    };
                    ClearForm()
                }, 200);
            };
        } else if(Email !== "") {
            if(EmailRegex.test(inputValue?.Email) === true) {
                if(SupplierName !== "" && CompanyName !== "") {
                    if(GetContactDetails.length > 0) {
                        let paramRequest = { 
                            "name": SupplierName, 
                            "email": Email, 
                            // "phone_code": PhoneNumber?.phone_code, 
                            "title": Title,
                            "website": WebSiteUrl,
                            // "phone": PhoneNumber?.phone, 
                            "company" : CompanyName, 
                            "address" : Address, 
                            "vat_registration_no" : VATRegistrationNo, 
                            "registration_no" : RegistrationNo, 
                            "has_contact": true,
                            "contact": GetContactDetails,
                            "DeviceId" : device_id
                        };
                        const paramfilter = Object.entries(paramRequest).filter((elm) => {
                            if(elm[1] !== "" || elm[0]==="email") {
                                return elm;
                            }
                        });
                        let param = { 
                            "transmit": "broadcast", 
                            "url": "add_supplier",
                            "request" : Object.fromEntries(paramfilter)
                        };
                        wsSend_request(websocket, param);
                        setTimeout(() => {
                            if(AddSupplierForPorduct === undefined) {
                                Navigate(SUPPLIER_MANAGEMENT_URL);
                            } else {
                                handleClose()
                            };
                            ClearForm()
                        }, 200);
                    } else {
                        let paramRequest = {
                            "name": SupplierName, 
                            "email": Email, 
                            // "phone_code": PhoneNumber?.phone_code, 
                            "title": Title,
                            // "phone": PhoneNumber?.phone, 
                            "company" : CompanyName, 
                            "address" : Address, 
                            "website": WebSiteUrl,
                            "vat_registration_no" : VATRegistrationNo, 
                            "registration_no" : RegistrationNo, 
                            "has_contact": false,
                            "DeviceId" : device_id
                        };
                        const paramfilter = Object.entries(paramRequest).filter((elm) => {
                            if(elm[1] !== "" || elm[0]==="email") {
                                return elm;
                            }
                        });
                        let param = { 
                            "transmit": "broadcast", 
                            "url": "add_supplier",
                            "request" : Object.fromEntries(paramfilter)
                        };
                        wsSend_request(websocket, param);
                        setTimeout(() => {
                            if(AddSupplierForPorduct === undefined) {
                                Navigate(SUPPLIER_MANAGEMENT_URL);
                            } else {
                                handleClose()
                            };
                            ClearForm()
                        }, 200);
                    };
                };
            } else {
                CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
            }
        } else if(PhoneNumber?.phone !== "") {
            if(CheckMobileNumber?.valid === true) {
                if(SupplierName !== "" && CompanyName !== "") {
                    if(GetContactDetails.length > 0) {
                        let paramRequest = { 
                            "name": SupplierName, 
                            "email": Email, 
                            "phone_code": PhoneNumber?.phone_code, 
                            "title": Title,
                            "website": WebSiteUrl,
                            "phone": PhoneNumber?.phone, 
                            "company" : CompanyName, 
                            "address" : Address, 
                            "vat_registration_no" : VATRegistrationNo, 
                            "registration_no" : RegistrationNo, 
                            "has_contact": true,
                            "contact": GetContactDetails,
                            "DeviceId" : device_id
                        };

                        const paramfilter = Object.entries(paramRequest).filter((elm) => {
                            if(elm[1] !== "" || elm[0]==="email") {
                                return elm;
                            }
                        });
                        let param = { 
                            "transmit": "broadcast", 
                            "url": "add_supplier",
                            "request" : Object.fromEntries(paramfilter)
                        };
                        wsSend_request(websocket, param);
                        setTimeout(() => {
                            if(AddSupplierForPorduct === undefined) {
                                Navigate(SUPPLIER_MANAGEMENT_URL);
                            } else {
                                handleClose()
                            };
                            ClearForm()
                        }, 200);
                    } else {
                        let paramRequest = { 
                            "name": SupplierName, 
                            "email": Email, 
                            "phone_code": PhoneNumber?.phone_code, 
                            "title": Title,
                            "phone": PhoneNumber?.phone, 
                            "company" : CompanyName, 
                            "address" : Address, 
                            "website": WebSiteUrl,
                            "vat_registration_no" : VATRegistrationNo, 
                            "registration_no" : RegistrationNo, 
                            "has_contact": false,
                            "DeviceId" : device_id
                        };
                        const paramfilter = Object.entries(paramRequest).filter((elm) => {
                            if(elm[1] !== "" || elm[0]==="email") {
                                return elm;
                            }
                        });
                        let param = { 
                            "transmit": "broadcast", 
                            "url": "add_supplier",
                            "request" : Object.fromEntries(paramfilter)
                        };
                        wsSend_request(websocket, param);
                        setTimeout(() => {
                            if(AddSupplierForPorduct === undefined) {
                                Navigate(SUPPLIER_MANAGEMENT_URL);
                            } else {
                                handleClose()
                            };
                            ClearForm()
                        }, 200);
                    };
                };
            } else {
                CheckValid(PhoneNumber, {type: 'phone', error: setErrorPhone });
            }
        } else {
            CheckValid(SupplierName, {type: 'SupplierName', error: setErrorSupplierName});
            CheckValid(CompanyName, {type: 'CompanyName', error: setErrorCompanyName});
        };
    };

    // clear form
    const ClearForm = () => {
        setInputValue({...inputValue, SupplierName: "", CompanyName: "", Email: "", Address: "", VATRegistrationNo: "", RegistrationNo: "" });
        setErrorSupplierName("");
        setErrorCompanyName("");
        setErrorEmail("");
        setErrorPhone("");
        setErrorAddress("");
        setErrorVATRegistrationNo("");
        setErrorRegistrationNo("");
        setPhoneNumber({...PhoneNumber, phone_code: "1", phone: ""});
    };


    return(<React.Fragment>
        <BackButton targeturl={SUPPLIER_MANAGEMENT_URL} title={"Back"} />
        <section className="dashboard_wrapper_form" >
            <Scrollbars 
                style={{ height: "calc(100vh - 150px)"}} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="scrollform">
                    <div className="row" style={{ margin: "0 auto" }}>
                        <div className="col-md-4">
                            <InputField
                                type="text"
                                value={SupplierName}
                                className={errorSupplierName !== "" ? "form-control error-form" : "form-control"}
                                placeholder="Enter Supplier Name"
                                label="Supplier Name <span class='redmednstar'>*</span>"
                                name="SupplierName"
                                onChange={handleChange}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'SupplierName', error: setErrorSupplierName})}
                                onKeyDown={EmptySpaceFieldValid}
                                errormessage={errorSupplierName}
                            />
                        </div>
                        <div className="col-md-4">
                            <InputField
                                type="text"
                                value={CompanyName}
                                className={errorCompanyName !== "" ? "form-control error-form" : "form-control"}
                                placeholder="Enter Company Name"
                                label="Company Name <span class='redmednstar'>*</span>"
                                name="CompanyName"
                                onChange={handleChange}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'CompanyName', error: setErrorCompanyName})}
                                onKeyDown={EmptySpaceFieldValid}
                                errormessage={errorCompanyName}
                            />
                        </div>
                        <div className="col-md-4">
                            <InputField
                                type="text"
                                value={Title}
                                className={errorTitle !== "" ? "form-control error-form" : "form-control"}
                                placeholder="Enter Title"
                                label="Title"
                                name="Title"
                                onChange={handleChange}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'Title', error: setErrorTitle})}
                                onKeyDown={EmptySpaceFieldValid}
                                errormessage={errorTitle}
                            />
                        </div>
                        <div className="col-md-4">
                            <InputField 
                                type="text"
                                value={Email}
                                className={errorEmail !== "" ? "form-control error-form" : "form-control"}
                                placeholder="Enter Email Address"
                                label="Email Address"
                                name="Email"
                                onChange={handleChange}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'email', error: setErrorEmail})}
                                onKeyDown={EmptySpaceFieldValid}
                                errormessage={errorEmail}
                            />
                        </div>
                        <div className="col-md-4">
                            <CountrySelectInput phone={PhoneNumber} setPhone={setPhoneNumber} CheckValid={CheckValid} errorPhone={errorPhone} setErrorPhone={setErrorPhone} />
                        </div>
                        <div className="col-md-4">
                            <InputField 
                                type="text"
                                value={WebSiteUrl}
                                className={errorWebSiteUrl !== "" ? "form-control error-form" : "form-control"}
                                placeholder="Enter WebSite"
                                label="Website"
                                name="WebSiteUrl"
                                onChange={handleChange}
                                // onKeyUp={(e) => CheckValid(e.target.value, {type: 'WebSiteUrl', error: setErrorWebSiteUrl})}
                                onKeyDown={EmptySpaceFieldValid}
                                errormessage={errorWebSiteUrl}
                            />
                        </div>
                        <div className="col-md-4">
                            <InputField 
                                type="text"
                                value={VATRegistrationNo}
                                className={errorVATRegistrationNo !== "" ? "form-control error-form" : "form-control"}
                                placeholder="Enter VAT Registration No."
                                label="VAT Registration No."
                                name="VATRegistrationNo"
                                onChange={handleChange}
                                // onKeyUp={(e) => CheckValid(e.target.value, {type: 'VATRegistrationNo', error: setErrorVATRegistrationNo})}
                                onKeyDown={EmptySpaceFieldValid}
                                errormessage={errorVATRegistrationNo}
                            />
                        </div>
                        <div className="col-md-4">
                            <InputField 
                                type="text"
                                value={RegistrationNo}
                                className={errorRegistrationNo !== "" ? "form-control error-form" : "form-control"}
                                placeholder="Enter Registration No."
                                label="Registration No."
                                name="RegistrationNo"
                                onChange={handleChange}
                                // onKeyUp={(e) => CheckValid(e.target.value, {type: 'RegistrationNo', error: setErrorRegistrationNo})}
                                onKeyDown={EmptySpaceFieldValid}
                                errormessage={errorRegistrationNo}
                            />
                        </div>
                        <div className="col-md-4">
                            <InputField 
                                type="text"
                                value={Address}
                                className={errorAddress !== "" ? "form-control error-form" : "form-control"}
                                placeholder="Enter Address"
                                label="Address"
                                name="Address"
                                onChange={handleChange}
                                // onKeyUp={(e) => CheckValid(e.target.value, {type: 'Address', error: setErrorAddress})}
                                onKeyDown={EmptySpaceFieldValid}
                                errormessage={errorAddress}
                            />
                        </div>
                    </div>
                    <div className="row" style={{ margin: "0 auto" }}>
                        <div className="col-md-12">
                            <div className="addmoreContact">
                                <div className="addcontact_head">
                                    <button className="btn add_more_contact" onClick={() => AddMoreContact()}>
                                        <i className="bi bi-plus-circle"></i> Add Contact Person
                                    </button>
                                </div>
                                <div className="addcontact_wrapper">
                                    {AddMoreContactState.map((elm, index) => {
                                        return(<div className="row" key={index}>
                                            <div className="col-md-11">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Name</label>
                                                            <div className="formInput">
                                                                <input type="text" className="form-control" value={elm?.name} placeholder="Enter Name" onChange={(e) => GetContactUpdate(e, elm?.id, "Name")} onKeyDown={EmptySpaceFieldValid} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Email Address</label>
                                                            <div className="formInput">
                                                                <input type="text" className="form-control" value={elm?.email} placeholder="Enter Email Address" onChange={(e) => GetContactUpdate(e, elm?.id, "Email")} onKeyDown={EmptySpaceFieldValid} />
                                                            </div>
                                                            {(elm?.email.length > 1 && EmailRegex.test(elm?.email) === false) && (<div className="errormendatorr"><i className="bi bi-x-circle-fill"></i>Please enter valid email address</div>)}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Title</label>
                                                            <div className="formInput">
                                                                <input type="text" className="form-control" value={elm?.title} placeholder="Enter Title" onChange={(e) => GetContactUpdate(e, elm?.id, "Title")} onKeyDown={EmptySpaceFieldValid} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Phone Number</label>
                                                            <div className="selectNoInputField multiPhoneCodeset">
                                                                <Dropdown className="inputBoxSelect">
                                                                    <Dropdown.Toggle className="selectEmojis" variant="success" id="dropdown-basic">
                                                                        {/* {elm?.phone_flag !== "" && <img src={FlagsUrl + getCountryName(elm) + ".svg"} className="flagsimg" alt="call image" />} */}
                                                                        <div className="countselet">+{elm?.phone_code}</div>
                                                                        <img src={CaretDown} className="arrowdownimg" alt="caret down" />
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="flagsDropdown">
                                                                        <div className="flagsDropdownMain">
                                                                            {Countries.filter((elm) => {
                                                                                if(CountrySelect === "") {
                                                                                    return elm;
                                                                                } else if(elm?.country_en.toLowerCase()?.includes(CountrySelect && CountrySelect?.toLowerCase())) {
                                                                                    return elm;
                                                                                } 
                                                                            }).map((flags, index) => {
                                                                                return(<Dropdown.Item key={index} onClick={() => SelectCountryCodeContactDetails(flags, elm?.id)}>
                                                                                    <img src={FlagsUrl + flags.country_code + ".svg"} />
                                                                                    <span>{flags?.country_en} (+{flags.phone_code})</span>
                                                                            </Dropdown.Item>)
                                                                            })}
                                                                        </div>
                                                                        <input type="text" value={CountrySelect} onChange={(e) => setCountrySelect(e.target.value)} placeholder="Search Country..." autoComplete="off" />
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                                <input type="number" className="inputBoxs" value={elm?.phone} placeholder="Enter Phone Number" onChange={(e) => GetContactUpdate(e, elm?.id, "Phone")} maxLength={10} onKeyDown={EmptySpaceFieldValid} />
                                                            </div>
                                                            {CheckValidMobileNo(elm.phone, elm?.phone_code)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-1" style={{ padding: "0px" }}>
                                                <div className="form-group">
                                                    <label>&nbsp;</label>
                                                    <button type="button" className="btn btn_deletebtn" onClick={() => DeleteMultiContact(elm?.id)}>
                                                        <i className="bi bi-trash3-fill"></i>
                                                        Delete
                                                    </button>
                                                </div>
                                            </div>
                                        </div>)
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group-btn"  style={{ textAlign: "right" }}>
                                <button className="btn addbtncmn" onClick={() => CreateSupplierFnct()}>Add</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Scrollbars>
        </section>
    </React.Fragment>)
}

export default AddEditSupplier;