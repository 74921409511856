/* eslint-disable */
import React, { useState } from "react";
import InputField from "../Common/InputField";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { Image } from "react-bootstrap";
import LoginImage from "../../assets/img/login_image.png";
import { Link } from "react-router-dom";
import { FORGOT_PASSWORD_URL, DASHBOARD_URL, LOGIN_API_URL } from "../Shared/constant";
import { useNavigate } from "react-router-dom";
import LoginAPI from "../../Api/LoginAPI";
import { useDispatch } from "react-redux";
import { getSelfDetails, getUserId, getAccessToken, checkLogin, getDeviceId } from "../../redux/actions/adminActions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BtnLoader from "../Common/BtnLoader";

const Login = () => {
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const [ inputValue, setInputValue ] = useState({ Email : "", Password: "" });
    const { Email, Password } = inputValue;
    const [ showpassword, setshowpassword ] = useState(false);
    const [ loaderStart, setloaderStart ] = useState(false);

    function uuidv4() {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    // Regex
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line

    // error message
    const [ errorEmail, setErrorEmail ] = useState("");
    const [ errorPassword, setErrorPassword ] = useState("");

    // show password
    const ShowHidePassword = () => {
        setshowpassword(!showpassword);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // login function
    const Login = async () => {
        if(inputValue?.Email !== "" && inputValue?.Password !== "") {
            if(EmailRegex.test(inputValue?.Email) === true && PasswordRegex.test(inputValue?.Password) === true) {
                setloaderStart(true);
                const jsonData = JSON.stringify({ email: inputValue?.Email, password: inputValue?.Password, device_type: "web", device_id: uuidv4()});
                const response = await LoginAPI(jsonData);
                if(response?.status === 200){
                    setTimeout(() => {
                        toast.success(response.msg);
                        Navigate(DASHBOARD_URL);
                        Dispatch(getSelfDetails(response?.data?.user));
                        Dispatch(getUserId(response?.data?.user?.id));
                        Dispatch(getAccessToken(response?.data?.access_token));
                        Dispatch(getDeviceId(response?.data?.device_id));
                        Dispatch(checkLogin("yes"));
                        clearForm();
                        setloaderStart(false);
                    }, 100);
                } else {
                    setloaderStart(false);
                    toast.error(response.error);
                }
            } else {
                CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
                CheckValid(inputValue?.Password, {type: 'password', error: setErrorPassword });
            }
        } else {
            CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
            CheckValid(inputValue?.Password, {type: 'password', error: setErrorPassword });
        }
    };

    // clear form
    const clearForm = () => {
        setInputValue({...inputValue, Email : "", Password: ""});
        setErrorEmail("");
        setErrorPassword("");
    };

    return(<section className="login_wrapper">
            <div className="login_wrap_slider">
                <Image src={LoginImage} alt="login image" />
                <h4>Sign In</h4>
                <p>Login to access your admin account</p>
            </div>
            <div className="login_form_wraps">
                <div className="loginTEXTS">
                    {/* <h1>Medical Equipment</h1> */}
                    <h4>Login to your account</h4>
                </div>
                <InputField 
                    type="text"
                    value={Email}
                    className={errorEmail !== "" ? "form-control error-form" : "form-control"}
                    placeholder="Enter Email Address"
                    label="Email Address"
                    name="Email"
                    onChange={handleChange}
                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'email', error: setErrorEmail})}
                    onKeyDown={EmptySpaceFieldValid}
                    errormessage={errorEmail}
                />
                <InputField
                    type={showpassword ? "text" : "password"}
                    value={Password}
                    className={errorPassword !== "" ? "form-control error-form" : "form-control"}
                    placeholder="Enter Password"
                    label="Password"
                    name="Password"
                    onChange={handleChange}
                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'password', error: setErrorPassword})}
                    onKeyDown={EmptySpaceFieldValid}
                    errormessage={errorPassword}
                    onClick={ShowHidePassword}
                    showpassword={showpassword}
                />
                <div className="form-group-btn">
                    <button className="btn loginCmn_btn" disabled={loaderStart}  onClick={() => Login()}>
                        {loaderStart && (<BtnLoader/>)} Sign In
                    </button>
                    <Link to={FORGOT_PASSWORD_URL} className="btn forgot_password_link"><i className="bi bi-lock-fill"></i> Forgot Password ?</Link>
                </div>
            </div>
    </section>)
}

export default Login;