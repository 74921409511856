/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import moment from "moment";
import { useSelector } from "react-redux";
import CommonPagination from "../Common/CommonPagination";
import { WebSocketContext } from "../../App";
import wsSend_request from "../../Api/ws/ws_request";
import { useParams } from "react-router-dom";
import BackButton from "../Common/BackButton";
import { MODEL_BRAND_MANAGEMENT_URL } from "../Shared/constant";
import CommonLoader from "../Common/CommonLoader";

const ViewBrandModels = () => {
    const Params = useParams();
    const { websocket } = useContext(WebSocketContext);
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const { brandList, userListIdWise, brandmodelsList, device_id, loaderMain ,selfInfo} = useSelector((state) => state.adminReducers);

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // get brand list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "brand_model_list",
            "request" : { 
                "brand_id" : Params?.roomId, 
                "limit" : userLimit, 
                "page_no" : currentPage,
                "DeviceId" : device_id
            }
        };
        wsSend_request(websocket, param);
    }, [ userLimit, currentPage ]);

    // brand name get
    const BrandNameGet = (brand_id) => {
        return brandList?.list.filter((elm) => brand_id === elm?.id)[0]?.name;
    };

    return(<section className="dashboard_wrapper">
    <div className="viewHeaderset">
        <h4>{BrandNameGet(Params?.roomId)}</h4>
        <DataTableFilter 
            filterType={"Brand"}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
        />
         <BackButton targeturl={MODEL_BRAND_MANAGEMENT_URL} title={"Back"} />
    </div>
    <div className="tableContent" style={{ height: "calc(100vh - 208px)" }}>
        <Scrollbars 
            style={{ height: brandmodelsList?.pagination?.total_records > 10 ? "calc(100% - 38px)" : "calc(100% - 0px)" }} 
            className="ScrollbarsWrapper"
            renderView={props => <div {...props} className="view"/>}
        >
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Model Name</th>
                            {selfInfo?.is_admin && (<th>Added By</th>)}
                            <th>Created at</th>
                        </tr>
                    </thead>
                    <tbody>
                        {brandmodelsList && brandmodelsList?.list?.map((user, index) => {
                            return(<React.Fragment key={index}>
                                <tr>
                                    <td>{user?.name}</td>
                                    {selfInfo?.is_admin && (<td>{userListIdWise[user?.added_by]?.email}</td>)}
                                    <td>{moment(user.created_at).format('LL')}</td>
                                </tr>
                            </React.Fragment>)
                        })}
                        {brandmodelsList && brandmodelsList?.list?.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={8}>Brand Models Not Found !</td></tr>}
                    </tbody>
                </table>
            </div>
        </Scrollbars>
        {/* pagination */}
        {brandmodelsList?.pagination?.total_records > 10 && (<CommonPagination 
            currentPage={currentPage}
            paginationLength={brandmodelsList?.pagination}
            currentFunction={currentFunction}
        />)}

        {/* loader start here */}
        {loaderMain && (<CommonLoader />)}
    </div>
    
</section>)
}

export default ViewBrandModels;