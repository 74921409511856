/* eslint-disable */
import React, { useState, useEffect } from "react";
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import CommonToggle from "../Common/CommonToggle";
import { Image, Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import MoreIcon from "../../assets/img/more_options.png";
import { ADD_USER_URL, EDIT_USER_URL } from "../Shared/constant";
import AvatarGet from "../Common/AvatarGet";
import { useSelector, useDispatch } from "react-redux";
import GetUserListAPI from "../../Api/GetUserListAPI";
import { getUserList } from "../../redux/actions/adminActions";
import CommonLogout from "../Common/CommonLogout";
import CommonPagination from "../Common/CommonPagination";
import CommonModel from "../Common/CommonModel";

const UserManagement = () => {
    const Dispatch = useDispatch();
    const { userList, accessToken, user_id, selfInfo } = useSelector((state) => state.adminReducers);
    const Navigate = useNavigate();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const [ paginationLength, setPaginationLength ] = useState({});
    const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };
    
    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    useEffect(() => {
        fetchData();
    }, [ userSearch, userType, userLimit, currentPage ]);

    // Get data from api
    const fetchData = async () => {
        const UserListsz = await GetUserListAPI(accessToken, userSearch === "" ? 0 : userSearch, userType, userLimit, currentPage);
        if(UserListsz?.status === 200) {
            Dispatch(getUserList(UserListsz.data));
            setPaginationLength(UserListsz.pagination);
        } else if(UserListsz?.status === 403) {
            CommonLogout();
            Dispatch(getUserList([]));
        } else {
            Dispatch(getUserList([]));
        };
    };

    // show more user details
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    // add new user
    const AddUserFnct = () => {
        Navigate(ADD_USER_URL);
    };

    // add new vendor
    const DeleteUserFnct = (user) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Delete User",
            subtitle: "Delete User",
            description: `Do you really want to delete to <h5>${user?.email}</h5>`,
            modalType: "not form",
            button: "Yes",
            Data: user
        });
    };

    // allow permission for user
    const AllowPermissGet = (data_, type, booleancheck) => {
        if(type === "is_active") {
            setShowModal({
                ...showModal,
                open: !showModal?.open,
                title: "Are you sure?",
                subtitle: `${type.split("_")[1]} User`,
                description: `Do you really want to ${booleancheck ? "deactivate" : "active"} to <h5>${data_?.email}</h5>`,
                modalType: "not form",
                button: "Yes",
                Data: data_
            });
        } else {
            setShowModal({
                ...showModal,
                open: !showModal?.open,
                title: "Are you sure?",
                subtitle: `${type.split("_")[1]} User`,
                description: `Do you really want to ${booleancheck ? "remove " : ""} ${type.split("_")[1]} to <h5>${data_?.email}</h5>`,
                modalType: "not form",
                button: "Yes",
                Data: data_
            });
        };
    }; 

    return(<section className="dashboard_wrapper">
         <DataTableFilter 
            filterType={"User (first & last name, email)"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.is_admin || (selfInfo?.permissions?.includes("add-user")))}
            ButtonSet={{
                buttontitle: "Add User",
                function: AddUserFnct
            }}
        />
        <div className="tableContent" style={{ height: window.screen.width > 540 ? "calc(100vh - 155px)" : "calc(100vh - 143px)" }}>
            <Scrollbars 
                style={{ height: paginationLength?.total_records > 11 ? (window.screen.width < 767 ? "calc(100% - 10px)" : "calc(100% - 38px)") : "calc(100% - 0px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th></th>
                                {/* <th>Picture	</th> */}
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Active</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userList && userList?.map((user, index) => {
                                if(user?.id !== user_id) {
                                    return(<React.Fragment key={index}>
                                        <tr className={activeTableDetails === user?.id ? "active" : ""}>
                                            <td style={{ width: '10px' }}>
                                                <button 
                                                    className={activeTableDetails === user?.id ? "btn actionbtn active" : "btn actionbtn"}
                                                    onClick={() => activeTableDetails === user?.id ? ElseMoreDetails() : ViewMoreDetails(user?.id)}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={activeTableDetails === user?.id ? true : false}
                                                >
                                                    <Image src={MoreIcon} alt="more icon" />
                                                </button>
                                            </td>
                                            {/* <td>
                                                <AvatarGet type="product" url={null} />
                                            </td> */}
                                            <td>{user?.first_name}</td>
                                            <td>{user?.last_name}</td>
                                            <td>{user?.email}</td>
                                            <td>
                                                {(user?.phone === null||user?.phone === "") ? "-" : "+"+user?.phone_code + " " + user?.phone}
                                            </td>
                                            <td>
                                                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("update-user"))) && (
                                                    <CommonToggle 
                                                        valueToggle={user?.is_active}
                                                        setValueToggle={() => AllowPermissGet(user, "is_active", user?.is_active)}
                                                        name={""}
                                                    />
                                                )}
                                            </td>
                                            <td className="respoactionUsers">
                                                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("update-user"))) && (<button type="button" className="btn actiontblebtn" onClick={() => Navigate(`${EDIT_USER_URL}/${user?.id}`)} >
                                                    <i className="bi bi-pencil-square"></i>
                                                </button>)}
                                                {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("delete-user"))) && (<button type="button" className="btn actiontblebtn" onClick={() => DeleteUserFnct(user)}>
                                                    <i className="bi bi-trash-fill" style={{ color: "red" }}></i>
                                                </button>)}
                                            </td>
                                        </tr>
                                        <Collapse in={activeTableDetails === user?.id ? true : false}>
                                            <tr className="tablecollapsewraps" id={"tablecollpase"+user?.id}>
                                                <td colSpan="8">
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <th style={{ width: "20%" }}>Admin</th>
                                                                <td>
                                                                    <CommonToggle 
                                                                        valueToggle={user?.is_admin}
                                                                        setValueToggle={() => AllowPermissGet(user, "is_admin", user?.is_admin)}
                                                                        name={""}
                                                                    />    
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th style={{ width: "20%" }}>Staff</th>
                                                                <td>
                                                                    <CommonToggle 
                                                                        valueToggle={user?.is_staff}
                                                                        setValueToggle={() => AllowPermissGet(user, "is_staff", user?.is_staff)}
                                                                        name={""}
                                                                    />    
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th style={{ width: "20%" }}>Address</th>
                                                                <td>{user?.address === null ? "-" : user?.address}</td>
                                                            </tr>
                                                            <tr>
                                                                <th style={{ width: "20%" }}>Registered At</th>
                                                                <td>{moment(user.created_at).format('LL')}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </Collapse>
                                    </React.Fragment>)
                                }
                            })}
                            {userList && userList.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={8}>User Not Found !</td></tr>}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>
            {/* pagination */}
            {paginationLength?.total_records > 11 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={paginationLength}
                currentFunction={currentFunction}
            />)}

            {showModal?.open && (<CommonModel setUserSearch={setUserSearch }  showModal={showModal} setShowModal={setShowModal} />)}
        </div>
    </section>)
}

export default UserManagement;