/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { WebSocketContext } from "../../App";
import wsSend_request from "../../Api/ws/ws_request";
import { USER_MANAGEMENT_URL } from "../Shared/constant";
import BackButton from "../Common/BackButton";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import CheckValid from "../Common/CheckValid";
import InputField from "../Common/InputField";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import CountrySelectInput from "../Common/CountrySelectInput/CountryMobileInput";
import CreateUserAPI from "../../Api/CreateUserAPI";
import UpdateUserAPI from "../../Api/UpdateUserAPI";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from "react-redux";
import CommonLogout from "../Common/CommonLogout";
import Multiselect from 'multiselect-react-dropdown';
import { addUserList, editUserList } from "../../redux/actions/adminActions";
import BtnLoader from "../Common/BtnLoader";
import { parsePhoneNumber, getRegionCodeForCountryCode } from "awesome-phonenumber";

const AddEditUser = () => {
    const Params = useParams();
    const Dispatch = useDispatch();
    const Location = useLocation()?.pathname?.split("/")[1];
    const { accessToken, permissionList, userList, device_id } = useSelector((state) => state.adminReducers);
    const GetCurrentUserSelected = userList.filter((user) => user?.id === Params?.roomId)[0];
    const Navigate = useNavigate();
    const [ inputValue, setInputValue ] = useState({ 
        Email: "", Fname: "", Lname: "", Address: "", Password: ""
    });
    const { Email, Fname, Lname, Address, Password } = inputValue;
    const [ PhoneNumber, setPhoneNumber ] = useState({
        phone_code: "1",
        phone: ""
    });
    const [ permissionGet, setPermissionGet ] = useState("");
    const [ showpassword, setshowpassword ] = useState(false);
    const { websocket } = useContext(WebSocketContext);
    const [ selectedValueUser, setselectedValueUser ] = useState("");
    const [ loader, setLoader ] = useState(false);

    // error message
    const [ errorEmail, setErrorEmail ] = useState("");
    const [ errorFname, setErrorFname ] = useState("");
    const [ errorLname, setErrorLname ] = useState("");
    const [ errorAddress, setErrorAddress ] = useState("");
    const [ errorPassword, setErrorPassword ] = useState("");
    const [ errorPhone, setErrorPhone ] = useState("");
    const [ errorPermission, setErrorPermission ] = useState("");

    // Regex
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line

    useEffect(() => {
        if(GetCurrentUserSelected !== undefined) {
            setInputValue({...inputValue, 
                Email: GetCurrentUserSelected?.email, 
                Fname: GetCurrentUserSelected?.first_name, 
                Lname: GetCurrentUserSelected?.last_name, 
                Address: GetCurrentUserSelected?.address, 
            });
            setPhoneNumber({...PhoneNumber, 
                phone_code: GetCurrentUserSelected?.phone_code,
                phone: GetCurrentUserSelected?.phone
            });
            setPermissionGet(GetCurrentUserSelected?.permissions === null ? "" : GetCurrentUserSelected?.permissions);
            setselectedValueUser(GetCurrentUserSelected?.permissions === null ? "" : GetCurrentUserSelected?.permissions.split(","));
        };
    }, [ GetCurrentUserSelected ]);

    useEffect(() => {
        if(accessToken) {
            let param = { 
                "transmit": "broadcast", 
                "url": "permission_list"
            };
            wsSend_request(websocket, param);
        }
    }, [ accessToken ])

    // Textfield common onchange function
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // show password
    const ShowHidePassword = () => {
        setshowpassword(!showpassword);
    };

    // select permsissions
    const PermissionAddID = [];
    const onSelectUser = (selectedList, selectedItem) => {
        selectedList.filter((elm) => {
            if(elm === "add-user" || elm === "update-user" || elm === "delete-user") {
                return PermissionAddID.push(elm,"user-list");
            } else if(elm === "add-product" || elm === "update-product") {
                return PermissionAddID.push(elm,"product-list","model-list","add-model", "brand-list","add-brand");
            } else if(elm === "add-model" || elm === "update-model" || elm === "delete-model") {
                return PermissionAddID.push(elm,"model-list");
            } else if(elm === "delete-product") {
                return PermissionAddID.push(elm,"product-list");
            } else if(elm === "add-brand" || elm === "update-brand" || elm === "delete-brand") {
                return PermissionAddID.push(elm,"brand-list");
            } else if(elm === "add-supplier" || elm === "update-supplier" || elm === "delete-supplier") {
                return PermissionAddID.push(elm,"supplier-list");
            } else if(elm === "add-cart") {
                return PermissionAddID.push(elm,"cart-list");
            } else {
                return PermissionAddID.push(elm);
            }
        });
        var unique = PermissionAddID.filter(onlyUnique);
        function onlyUnique(value, index, array) {
            return array.indexOf(value) === index;
        }
        setselectedValueUser(unique);
        setPermissionGet(unique.toString());
    };

    // remove permsissions
    const onRemoveUser = (selectedList, removedItem) => {
        selectedList.filter((elm) => {
            PermissionAddID.push(elm);
        });
        setselectedValueUser(selectedList);
        setPermissionGet(PermissionAddID.toString());
    };
    
    const CountryStates = getRegionCodeForCountryCode(PhoneNumber?.phone_code);
    const CheckMobileNumber = parsePhoneNumber(PhoneNumber?.phone?.toString(), { regionCode: CountryStates });
    
    // edit profile
    const AddUserFunct = async () => {
        if(GetCurrentUserSelected !== undefined && Location === "edit-user") {
            // if(inputValue?.Password?.length > 0 && inputValue?.Email !== "") {
            //     if(PasswordRegex.test(inputValue?.Password) === true && EmailRegex.test(inputValue?.Email) === true) {
            //         let param = { 
            //             "transmit": "broadcast", 
            //             "url": "update_user",
            //             "request":{ 
            //                 "staff_id": GetCurrentUserSelected?.id,
            //                 "first_name": inputValue?.Fname,
            //                 "last_name": inputValue?.Lname,
            //                 "phone_code": PhoneNumber?.phone_code,
            //                 "phone": PhoneNumber?.phone,
            //                 "email": inputValue?.Email, 
            //                 "address": inputValue?.Address,
            //                 "password": inputValue?.Password,
            //                 "permissions": permissionGet,
            //                 "DeviceId" : device_id
            //             }
            //         };
            //         wsSend_request(websocket, param);
            //         setTimeout(() => {
            //             Navigate(USER_MANAGEMENT_URL);
            //             ResetForm();
            //         }, 200);
            //     } else {
            //         CheckValid(inputValue?.Password, {type: 'password', error: setErrorPassword });
            //     }
            // } else if(PhoneNumber?.phone !== "") {
            //     if(CheckMobileNumber?.valid === true) {
            //         if(inputValue?.Email !== "" || inputValue?.Password !== "" || inputValue?.Fname !== "" || inputValue?.Lname !== "" || inputValue?.Address !== "" || permissionGet !== "") {
            //             if(EmailRegex.test(inputValue?.Email) === true || PasswordRegex.test(inputValue?.Password) === true) {
            //                 let param = { 
            //                     "transmit": "broadcast", 
            //                     "url": "update_user",
            //                     "request":{ 
            //                         "staff_id": GetCurrentUserSelected?.id,
            //                         "first_name": inputValue?.Fname,
            //                         "last_name": inputValue?.Lname,
            //                         "phone_code": PhoneNumber?.phone_code,
            //                         "phone": PhoneNumber?.phone,
            //                         "email": inputValue?.Email, 
            //                         "address": inputValue?.Address,
            //                         "password": inputValue?.Password,
            //                         "permissions": permissionGet,
            //                         "DeviceId" : device_id
            //                     }
            //                 };
            //                 wsSend_request(websocket, param);
            //                 setTimeout(() => {
            //                     Navigate(USER_MANAGEMENT_URL);
            //                     ResetForm();
            //                 }, 200);
            //             } else {
            //                 CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
            //                 CheckValid(inputValue?.Password, {type: 'password', error: setErrorPassword });
            //             }
            //         } else {
            //             CheckValid(inputValue?.Fname, {type: 'Fname', error: setErrorFname });
            //             CheckValid(inputValue?.Lname, {type: 'Lname', error: setErrorLname });
            //             // CheckValid(inputValue?.Address, {type: 'Address', error: setErrorAddress });
            //             CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
            //             CheckValid(inputValue?.Password, {type: 'password', error: setErrorPassword });
            //             CheckValid(permissionGet, {type: 'permission', error: setErrorPermission });
            //         };
            //     } else {
            //         CheckValid(PhoneNumber, {type: 'phone', error: setErrorPhone });
            //     }
            // } else {
            //     if(inputValue?.Email !== "" || inputValue?.Password !== "" || inputValue?.Fname !== "" || inputValue?.Lname !== "" || inputValue?.Address !== "" || permissionGet !== "") {
            //         if(EmailRegex.test(inputValue?.Email) === true || PasswordRegex.test(inputValue?.Password) === true) {
            //             let param = { 
            //                 "transmit": "broadcast", 
            //                 "url": "update_user",
            //                 "request":{ 
            //                     "staff_id": GetCurrentUserSelected?.id,
            //                     "first_name": inputValue?.Fname,
            //                     "last_name": inputValue?.Lname,
            //                     "phone_code": PhoneNumber?.phone_code,
            //                     "phone": PhoneNumber?.phone,
            //                     "email": inputValue?.Email, 
            //                     "address": inputValue?.Address,
            //                     "password": inputValue?.Password,
            //                     "permissions": permissionGet,
            //                     "DeviceId" : device_id
            //                 }
            //             };
            //             wsSend_request(websocket, param);
            //             setTimeout(() => {
            //                 Navigate(USER_MANAGEMENT_URL);
            //                 ResetForm();
            //             }, 200);
            //         } else {
            //             CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
            //             CheckValid(inputValue?.Password, {type: 'password', error: setErrorPassword });
            //         }
            //     } else {
            //         CheckValid(inputValue?.Fname, {type: 'Fname', error: setErrorFname });
            //         CheckValid(inputValue?.Lname, {type: 'Lname', error: setErrorLname });
            //         // CheckValid(inputValue?.Address, {type: 'Address', error: setErrorAddress });
            //         CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
            //         CheckValid(inputValue?.Password, {type: 'password', error: setErrorPassword });
            //         CheckValid(permissionGet, {type: 'permission', error: setErrorPermission });
            //     };
            // }
            if(inputValue?.Email !== "" && inputValue?.Fname !== "" && inputValue?.Lname !== "" && PhoneNumber?.phone !== "" && permissionGet !== "") {
                if(EmailRegex.test(inputValue?.Email) === true && CheckMobileNumber?.valid === true) {
                    let param = { 
                        "transmit": "broadcast", 
                        "url": "update_user",
                        "request":{ 
                            "staff_id": GetCurrentUserSelected?.id,
                            "first_name": inputValue?.Fname,
                            "last_name": inputValue?.Lname,
                            "phone_code": PhoneNumber?.phone_code,
                            "phone": PhoneNumber?.phone,
                            "email": inputValue?.Email, 
                            "address": inputValue?.Address,
                            "password": inputValue?.Password,
                            "permissions": permissionGet,
                            "DeviceId" : device_id
                        }
                    };
                    wsSend_request(websocket, param);
                    setTimeout(() => {
                        Navigate(USER_MANAGEMENT_URL);
                        ResetForm();
                    }, 200);
                } else {
                    CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
                    CheckValid(PhoneNumber, {type: 'phone', error: setErrorPhone });
                }
            } else {
                CheckValid(inputValue?.Fname, {type: 'Fname', error: setErrorFname });
                CheckValid(inputValue?.Lname, {type: 'Lname', error: setErrorLname });
                // CheckValid(inputValue?.Address, {type: 'Address', error: setErrorAddress });
                CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
                CheckValid(PhoneNumber, {type: 'phone', error: setErrorPhone });
                CheckValid(permissionGet, {type: 'permission', error: setErrorPermission });
            };
        } else {
            if(inputValue?.Email !== "" && inputValue?.Password !== "" && inputValue?.Fname !== "" && inputValue?.Lname !== "" && PhoneNumber?.phone !== "" && permissionGet !== "") {
                if(EmailRegex.test(inputValue?.Email) === true && PasswordRegex.test(inputValue?.Password) === true && CheckMobileNumber?.valid === true) {
                    setLoader(true);
                    const jsonData = JSON.stringify({ 
                        first_name: inputValue?.Fname,
                        last_name: inputValue?.Lname,
                        phone_code: PhoneNumber?.phone_code,
                        phone: PhoneNumber?.phone,
                        email: inputValue?.Email, 
                        address: inputValue?.Address,
                        password: inputValue?.Password,
                        permissions: permissionGet,
                    });
                    const response = await CreateUserAPI(jsonData, accessToken);
                    if(response?.status === 200){
                        setTimeout(() => {
                            toast.success(response.msg);
                            Dispatch(addUserList(response?.data));
                            ResetForm();
                            Navigate(USER_MANAGEMENT_URL);
                            setLoader(false);
                        }, 100);
                    } else if(response?.status === 403){
                        CommonLogout(accessToken, Dispatch);
                    } else {
                        toast.error(response.error);
                        setLoader(false);
                    }
                } else {
                    CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
                    CheckValid(inputValue?.Password, {type: 'password', error: setErrorPassword });
                    CheckValid(PhoneNumber, {type: 'phone', error: setErrorPhone });
                }
            } else {
                CheckValid(inputValue?.Fname, {type: 'Fname', error: setErrorFname });
                CheckValid(inputValue?.Lname, {type: 'Lname', error: setErrorLname });
                // CheckValid(inputValue?.Address, {type: 'Address', error: setErrorAddress });
                CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
                CheckValid(inputValue?.Password, {type: 'password', error: setErrorPassword });
                CheckValid(PhoneNumber, {type: 'phone', error: setErrorPhone });
                CheckValid(permissionGet, {type: 'permission', error: setErrorPermission });
            };
        };
    };

    const ResetForm = () => {
        setInputValue({...inputValue, Email: "", Fname: "", Lname: "", Address: "", Password: ""});
        setPhoneNumber({...PhoneNumber, phone: "" });
        setPermissionGet("");
    };

    return(<section className="dashboard_wrapper_form" >
    <BackButton targeturl={USER_MANAGEMENT_URL} title={"Back"} />
    <Scrollbars 
        style={{ height: "calc(100%)" }} 
        className="ScrollbarsWrapper"
        renderView={props => <div {...props} className="view"/>}
    >
        <div className="row" style={{ margin: "0 auto" }}>
            <div className="col-md-4">
                <InputField
                    type="text"
                    value={Fname}
                    className={errorFname !== "" ? "form-control error-form" : "form-control"}
                    placeholder="Enter First Name"
                    label="First Name <span class='redmednstar'>*</span>"
                    name="Fname"
                    onChange={handleChange}
                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'Fname', error: setErrorFname})}
                    onKeyDown={EmptySpaceFieldValid}
                    errormessage={errorFname}
                />
            </div>
            <div className="col-md-4">
                <InputField
                    type="text"
                    value={Lname}
                    className={errorLname !== "" ? "form-control error-form" : "form-control"}
                    placeholder="Enter Last Name"
                    label="Last Name <span class='redmednstar'>*</span>"
                    name="Lname"
                    onChange={handleChange}
                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'Lname', error: setErrorLname})}
                    onKeyDown={EmptySpaceFieldValid}
                    errormessage={errorLname}
                />
            </div>
            <div className="col-md-4">
                <InputField 
                    type="text"
                    value={Email}
                    className={errorEmail !== "" ? "form-control error-form" : "form-control"}
                    placeholder="Enter Email Address"
                    label="Email Address <span class='redmednstar'>*</span>"
                    name="Email"
                    onChange={handleChange}
                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'email', error: setErrorEmail})}
                    onKeyDown={EmptySpaceFieldValid}
                    errormessage={errorEmail}
                />
            </div>
            <div className="col-md-4">
                <CountrySelectInput errorPhone={errorPhone} phone={PhoneNumber} setPhone={setPhoneNumber} CheckValid={CheckValid} setErrorPhone={setErrorPhone} />
            </div>
            <div className="col-md-4">
                <InputField
                    type={showpassword ? "text" : "password"}
                    value={Password}
                    className={errorPassword !== "" ? "form-control error-form" : "form-control"}
                    placeholder="Enter Password"
                    label="Enter Password <span class='redmednstar'>*</span>"
                    name="Password"
                    onChange={handleChange}
                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'newpassword', error: setErrorPassword})}
                    onKeyDown={EmptySpaceFieldValid}
                    errormessage={errorPassword}
                    onClick={ShowHidePassword}
                    showpassword={showpassword}
                />
            </div>
            <div className="col-md-4">
                <div className="form-group">
                    <label>Permission <span className='redmednstar'>*</span></label>
                    <div className="filedsearchReltive">
                        <Multiselect
                            options={permissionList} // Options to display in the dropdown
                            selectedValues={selectedValueUser} // Preselected value to persist in dropdown
                            onSelect={onSelectUser} // Function will trigger on select event
                            onRemove={onRemoveUser} // Function will trigger on remove event
                            displayValue={"value"} // Property name to display in the dropdown options
                            singleSelect={false} // only single category select / for multiple category select remove singleSelect 
                            isObject={false}
                            placeholder={"Select Permission"}
                        />
                        {(permissionGet === "" && errorPermission !== "") && (<div className="errormendatorr"><i className="bi bi-x-circle-fill"></i>{errorPermission}</div>)}
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <InputField
                    type="text"
                    value={Address}
                    className={errorAddress !== "" ? "form-control error-form" : "form-control"}
                    placeholder="Enter Address"
                    label="Address"
                    name="Address"
                    onChange={handleChange}
                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'Address', error: setErrorAddress})}
                    onKeyDown={EmptySpaceFieldValid}
                    errormessage={errorAddress}
                />
            </div>
            <div className="col-md-12">
                <div className="form-group-btn" style={{ textAlign: "right" }}>
                    <button type="button" className="btn addbtncmn" onClick={() => AddUserFunct()}>
                        {loader && (<BtnLoader />)}
                        {Location === "edit-user" ? "Save" : "Create"}
                    </button>
                </div>
            </div>
        </div>
    </Scrollbars>
</section>)
}

export default AddEditUser;