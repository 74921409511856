/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import { useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";

const CommonewModel = (props) => {
    const { websocket } = useContext(WebSocketContext);
    const { showModalNew, setShowModalNew,setCurrentPage} = props;
    const { device_id } = useSelector((state) => state.adminReducers);

    // close modal
    const handleClose = () => {
        setShowModalNew({...showModalNew, open: false});
    };

    const CommonFunction = async () => {
        if(showModalNew?.subtitle === "Active Product") {
            let param = { 
                "transmit": "broadcast", 
                "url": "update_product",
                "request":{
                    "product_id" : showModalNew?.Data?.id,
                    "is_active" : showModalNew?.Data?.is_active ? 0 : 1,
                    "pi_no" : showModalNew?.Data?.pi_no, 
                    "avatar" : showModalNew?.Data?.avatar === "" ? null : showModalNew?.Data?.avatar,
                    "name": showModalNew?.Data?.name, 
                    "brand" : showModalNew?.Data?.brand,
                    "model" : showModalNew?.Data?.model, 
                    "quantity" : showModalNew?.Data?.quantity, 
                    "supplier" : showModalNew?.Data?.supplier, 
                    "unit_cost_price" : showModalNew?.Data?.unit_cost_price, 
                    "incoterm" : showModalNew?.Data?.incoterm, 
                    "hs_code" : showModalNew?.Data?.hs_code, 
                    "dimensions" : showModalNew?.Data?.dimensions, 
                    "currency" : showModalNew?.Data?.currency,
                    "floor" : showModalNew?.Data?.floor,
                    "ps" : showModalNew?.Data?.ps,
                    "date" : showModalNew?.Data?.date,
                    "client_code" : showModalNew?.Data?.client_code,
                    "pc_code" : showModalNew?.Data?.pc_code,
                    "description_french" : showModalNew?.Data?.description_french,
                    "description_english" : showModalNew?.Data?.description_english,
                    "department" : showModalNew?.Data?.department,
                    "unit" : showModalNew?.Data?.unit,
                    "ucp_usd" : showModalNew?.Data?.ucp_usd,
                    "tcp_usd" : showModalNew?.Data?.tcp_usd,
                    "usp_usd" : showModalNew?.Data?.usp_usd,
                    "tsp_usd" : showModalNew?.Data?.tsp_usd,
                    "lot_number" : showModalNew?.Data?.lot_number,
                    "brochure" : showModalNew?.Data?.brochure,
                    "remarks" : showModalNew?.Data?.remarks,
                    "country_of_origin" : showModalNew?.Data?.country_of_origin,
                    "catalog" : showModalNew?.Data?.catalog,
                    "cs" : showModalNew?.Data?.cs,
                    "ce" : showModalNew?.Data?.ce,
                    "iso" : showModalNew?.Data?.iso,
                    "maf" : showModalNew?.Data?.maf,
                    "warranty" : showModalNew?.Data?.warranty,
                    "lead_time" : showModalNew?.Data?.lead_time,
                    "category" : showModalNew?.Data?.category,
                    "fator" : showModalNew?.Data?.fator,
                    "DeviceId" : device_id
                }
            };
            wsSend_request(websocket, param);
            CommonListUpdate("product_list");
            handleClose();
        } else if(showModalNew?.subtitle === "Delete Product") {
            let param = { 
                "transmit": "broadcast", 
                "url": "delete_product",
                "request":{
                    "product_id" : showModalNew?.Data?.id,
                    "DeviceId" : device_id
                }
            };
            wsSend_request(websocket, param);
            CommonListUpdate("product_list");
            handleClose();
        } 
    };

    // When editing API calling, at that time list API calling.
    const CommonListUpdate = (url) => {
        if(url)  {
            let param = { 
                "transmit": "broadcast", 
                "url": url,
                "request" : { 
                    "filter" : "all", 
                    "limit" : 10, 
                    "page_no" : 1, 
                    "search" : "", 
                    "DeviceId" : device_id
                }
            };
            wsSend_request(websocket, param);
            setCurrentPage(1);
        }
    };

    return(<Modal className={showModalNew?.title === "Create Supplier" ? "commonModal addSupplierModel" : "commonModal"} show={showModalNew?.open} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title style={{ width: showModalNew?.title === "Are you sure?" ? "100%" : ""}}>{showModalNew?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {showModalNew?.modalType === "form" ? (
                <></>
            ) : (<>
                <div className="notformsection">
                    {showModalNew?.subtitle === "Logout" && (<i className="bi bi-box-arrow-right logoutIconbtn"></i>)}
                    <p dangerouslySetInnerHTML={{__html: showModalNew?.description}}></p>
                </div>
            </>)}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" className="addbtncmn" onClick={CommonFunction}>
                {showModalNew?.button}
            </Button>
        </Modal.Footer>
    </Modal>)
}

export default CommonewModel;