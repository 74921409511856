export const LOGIN_URL = "/login";
export const FORGOT_PASSWORD_URL = "/forgot-password";
export const RESET_PASSWORD_URL = "/reset-password";
export const DASHBOARD_URL = "/dashboard";
export const USER_MANAGEMENT_URL = "/user-management";
export const ADD_USER_URL = "/add-user";
export const EDIT_USER_URL = "/edit-user";
export const VIEW_PROFILE_URL = "/profile";
export const PRODUCT_MANAGEMENT_URL = "/product-management";
export const ADD_PRODUCT_MANAGEMENT_URL = "/add-product";
export const EDIT_PRODUCT_MANAGEMENT_URL = "/edit-product";
export const VIEW_PRODUCT_MANAGEMENT_URL = "/view-product";
export const CART_MANAGEMENT_URL = "/cart";
export const SUPPLIER_MANAGEMENT_URL = "/supplier";
export const ADD_SUPPLIER_MANAGEMENT_URL = "/add-supplier";
export const EDIT_SUPPLIER_MANAGEMENT_URL = "/edit-supplier";
export const VIEW_SUPPLIER_MANAGEMENT_URL = "/view-supplier";
export const MODEL_BRAND_MANAGEMENT_URL = "/brand-and-models";
export const VIEW_MODEL_BRANDS_MANAGEMENT_URL = "/view-model-brands";
export const CATEGORY_MANAGEMENT_URL = "/category-management";
export const SETTINGS_MANAGEMENT_URL = "/Fields-management";


// Api login urls
export const API_URL = process.env.REACT_APP_BASE_URL;
export const LOGIN_API_URL = API_URL + "user/login";
export const LOGOUT_API_URL = API_URL + "user/logout";
export const GET_DASHBOARD_API_URL = API_URL + "admin/dashboard";
export const FORGOT_PASSWORD_API_URL = API_URL + "user/forgot-password";
export const CREATE_PASSWORD_API_URL = API_URL + "user/create-password";
export const ADMIN_ADD_USER_API_URL = API_URL + "admin/user/add";
export const ADMIN_UPDATE_USER_API_URL = API_URL + "admin/user/update";
export const ADMIN_GET_USER_LIST_API_URL = API_URL + "admin/user/list";
export const ADMIN_DELETE_USER_LIST_API_URL = API_URL + "admin/user/delete";

// upload file urls
export const UPLOAD_FILE_API_URL = API_URL + "file/upload";

// import product file urls
export const IPMPORT_PRODUCT_FILE_API_URL = API_URL + "file/import/product";

// export product urls
export const EXPORT_PRODUCT_FILE_API_URL = API_URL + "file/export/product";
