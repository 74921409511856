/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import moment from "moment";
import { useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonPagination from "../Common/CommonPagination";
import CommonModel from "../Common/CommonModel";

const SettingsManagement = () => {
    const { websocket } = useContext(WebSocketContext);
    const { selfInfo, settinglists } = useSelector((state) => state.adminReducers);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    // get supplier list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "product_settings_list",
            "request" : { 
                "limit" : userLimit, 
                "page_no" : currentPage, 
                "search" : userSearch.trim()
            }
        };
        wsSend_request(websocket, param);
    }, [ userSearch, userLimit, currentPage ]);

    // add new category
    const AddCategoryFnct = () => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Add Field",
            subtitle: "Add Field",
            description: ``,
            modalType: "form",
            button: "Yes"
        });
    };

    // edit category
    const EditCategory = (data_) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Edit Field",
            subtitle: "Edit Field",
            description: ``,
            modalType: "form",
            button: "Save",
            Data: data_
        });
    };

    // delete category
    const DeleteCategoryFnct = (data_) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Delete Field",
            subtitle: "Delete Field",
            description: `Do you really want to delete <h5>${data_?.name}</h5>`,
            modalType: "not form",
            button: "Yes",
            Data: data_
        });
    };

    return(<section className="dashboard_wrapper">
        <DataTableFilter 
            filterType={"Fields (name)"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            buttonvisible={(selfInfo?.is_admin || (selfInfo?.permissions?.includes("add-product-setting")))}
            ButtonSet={{
                buttontitle: "Add Field",
                function: AddCategoryFnct
            }}
        />
        <div className="tableContent" style={{ height: window.screen.width > 540 ? "calc(100vh - 155px)" : "calc(100vh - 143px)" }}>
            <Scrollbars 
                style={{ height: settinglists?.pagination?.total_records > 10 ? (window.screen.width < 767 ? "calc(100% - 10px)" : "calc(100% - 38px)") : "calc(100% - 0px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Created At</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {settinglists && settinglists?.list?.map((user, index) => {
                                return(<tr key={index}>
                                        <td>{user?.name}</td>
                                        <td>{moment(user.created_at).format('LL')}</td>
                                        <td className="respoactionSupplier">
                                            {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("update-product-setting"))) && (<button type="button" className="btn actiontblebtn" onClick={() => EditCategory(user)}>
                                                <i className="bi bi-pencil-square"></i>
                                            </button>)}
                                            {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("delete-product-setting"))) && (<button type="button" className="btn actiontblebtn" onClick={() => DeleteCategoryFnct(user)}>
                                                <i className="bi bi-trash-fill" style={{ color: "red" }}></i>
                                            </button>)}
                                        </td>
                                    </tr>)})}
                            {settinglists && settinglists?.list.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={8}>Fields Not Found !</td></tr>}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>
            {/* pagination */}
            {settinglists?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={settinglists?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        {showModal?.open && (<CommonModel setUserSearch={setUserSearch} setCurrentPage={setCurrentPage}  showModal={showModal} setShowModal={setShowModal} />)}
</section>)
}

export default SettingsManagement;