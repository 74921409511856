/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import AvatarGet from "../Common/AvatarGet";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { VIEW_PRODUCT_MANAGEMENT_URL, PRODUCT_MANAGEMENT_URL } from "../Shared/constant";
import ExportIcon from "../../assets/img/export_icon.png";
import { Image, Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { RemoveCartList, addToCartList, addMultipleQauntityCart, removeMultipleQauntityCart } from "../../redux/actions/adminActions";
import { useNavigate, Link } from "react-router-dom";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import ExportProductAPI from "../../Api/ExportProductAPI";

const Cart = () => {
    const { websocket } = useContext(WebSocketContext);
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const [ userSearch, setUserSearch ] = useState("");
    const { selfInfo, addToCartLists, brandlistall, modellistall, productListAll, accessToken } = useSelector((state) => state.adminReducers);

    // get product list
    useEffect(() => {
        if(userSearch !== "") {
            let param = { 
                "transmit": "broadcast", 
                "url": "product_list",
                "request" : { 
                    "filter" : "all", 
                    "limit" : 5000, 
                    "page_no" : 1, 
                    "search" : userSearch.trim()
                }
            };
            wsSend_request(websocket, param);
        };
    }, [ userSearch ]);

    // brand name get
    const BrandNameGet = (brand_id) => {
        return brandlistall?.filter((elm) => brand_id === elm?.id)[0]?.name;
    };

    // model name get
    const ModelNameGet = (model_id) => {
        return modellistall?.filter((elm) => model_id === elm?.id)[0]?.name;
    };

    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    const arrayUniqueByKey = addToCartLists.map((elm) => elm?.data);
    const CartExportArray = [];
    if(arrayUniqueByKey?.length > 0) {
        arrayUniqueByKey.filter((elm) => {
            const Objs = {
                id : elm?.id,
                quantity : elm?.quantity,
                total_price : elm?.unit_cost_price,
            };
            CartExportArray.push(Objs);
        });
    }

    // export all product
    const ExportFunction = async () => {
        if(addToCartLists?.length > 0) {
            const jsonData = JSON.stringify({ 
                products: CartExportArray
            });
            const response = await ExportProductAPI(jsonData, accessToken);
            if(response?.status === 200) {
                const ResultFile = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response.data;
                var link = document.createElement('a');
                link.href = ResultFile;
                link.download = "sample.xlsx";
                link.click();
            };
        };
    };

    const ObjectUserListId = {}
    arrayUniqueByKey.map((elm) => {
        ObjectUserListId[elm?.id] = elm
    });

    return(<section className="dashboard_wrapper">
    <div className="card-header-New card-header-New-header">
        <div className="dropdown_wrapper">
            <Dropdown className="show ">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <div className="searchBoxwithbtn">
                        <input type="text" className="form-control" value={userSearch} onChange={(e) => setUserSearch(e.target.value)} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder={`Search Product (Name , Item No)...`}/>
                        {userSearch === "" ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                            <path d="M21 21l-6 -6"></path>
                        </svg>) : (<i className="bi bi-x-circle-fill searchclear" onClick={() => ClearSearchFiled()}></i>)}
                    </div>
                </Dropdown.Toggle>
                {userSearch && (<Dropdown.Menu>
                    {productListAll.map((elm, index) => {
                        if(elm?.id !== ObjectUserListId[elm?.id]?.id) {
                        return(<Dropdown.Item className="product_list_datas" key={index}>
                                <AvatarGet type="product" url={elm?.view_thumbnail_url} />
                                <div className="porduct_wrapper_data">
                                    <span>
                                        <label style={{ fontWeight: "500" }}>Item Code:</label> {elm?.item_code}
                                        </span>
                                    {elm?.name}
                                </div>
                                {(selfInfo?.is_admin || selfInfo?.permissions?.includes("add-cart")) && (<button type="button" className="btn addtocartebtn" onClick={() => Dispatch(addToCartList({productz: elm, mainQuantity: elm?.quantity, mainCost: elm?.unit_cost_price}))} >
                                    <i className="bi bi-cart-fill"></i> Add To Cart
                                </button>)}
                        </Dropdown.Item>)}})}
                </Dropdown.Menu>)}
            </Dropdown>
        </div>
        
        <Link to={PRODUCT_MANAGEMENT_URL} className="header_backbtn">
            <i className="bi bi-arrow-left"></i>  {window.screen.width > 541 && (<>&nbsp; Back</>)}
        </Link>
    </div>
    <ul className="cart_wrappperr">
        <Scrollbars 
            style={{ height: "calc(100% - 0px)"}} 
            className="ScrollbarsWrapper"
            renderView={props => <div {...props} className="view"/>}
        >
            {addToCartLists?.length > 0 ? addToCartLists.map((cart, index) => {
                return(<li className="productcartList" key={index}>
                    <AvatarGet type="product" url={cart?.data?.view_thumbnail_url} />
                    {window.screen.width > 541 && (<div className="cartdetails">
                        <h4>{cart?.data?.name}</h4>
                        <ul className="size_product">
                            <li>
                                <label>Item Code:</label> &nbsp;
                                {cart?.data?.item_code}
                            </li>
                            <li>
                                <label>Brand:</label> &nbsp;
                                {BrandNameGet(cart?.data?.brand)}
                            </li>
                            <li>
                                <label>Model:</label> &nbsp;
                                {ModelNameGet(cart?.data?.model)}
                            </li>
                        </ul>
                        <div className="cart_btn_group">
                            <button className="btn viewCartbtn" onClick={() => Navigate(`${VIEW_PRODUCT_MANAGEMENT_URL}/${cart?.data?.id}`)}><i className="bi bi-eye"></i> View</button>
                            <button className="btn removercart" onClick={() => Dispatch(RemoveCartList(cart))}><i className="bi bi-trash3"></i> Remove Cart</button>
                        </div>
                    </div>)}
                    {window.screen.width > 541 && (<div className="cartbtn_wraps">
                        <div className="quantity_product">
                            <label>Quantity</label>
                            <div className="productQuantyset">
                                <button className="btn removeQuantity" disabled={cart?.data?.quantity === 0} onClick={() => cart?.data?.quantity === cart?.mainQuantity ? Dispatch(RemoveCartList(cart)) : Dispatch(removeMultipleQauntityCart({product: cart, mainQuantity: cart?.mainQuantity, mainCost: cart?.mainCost}))}>
                                    <i className="bi bi-dash-circle-fill"></i>
                                </button>
                                <div className="productQuantyBox">{cart?.data?.quantity}</div>
                                <button className="btn addQuantity" onClick={() => Dispatch(addMultipleQauntityCart({product: cart, mainQuantity: cart?.mainQuantity, mainCost: cart?.mainCost}))}>
                                    <i className="bi bi-plus-circle-fill"></i>
                                </button>
                            </div>
                        </div>
                        <h2>${cart?.data?.unit_cost_price}</h2>
                    </div>)}
                    {window.screen.width < 541 && (
                        <div className="cart_mobile_responsive">
                            <div className="cartdetails">
                                <h4>{cart?.data?.name}</h4>
                                <ul className="size_product">
                                    <li>
                                        <label>Item Code:</label> &nbsp;
                                        {cart?.data?.item_code}
                                    </li>
                                    <li>
                                        <label>Brand:</label> &nbsp;
                                        {BrandNameGet(cart?.data?.brand)}
                                    </li>
                                    <li>
                                        <label>Model:</label> &nbsp;
                                        {ModelNameGet(cart?.data?.model)}
                                    </li>
                                </ul>
                                <div className="cart_btn_group">
                                    <button className="btn viewCartbtn" onClick={() => Navigate(`${VIEW_PRODUCT_MANAGEMENT_URL}/${cart?.data?.id}`)}><i className="bi bi-eye"></i> View</button>
                                    <button className="btn removercart" onClick={() => Dispatch(RemoveCartList(cart))}><i className="bi bi-trash3"></i> Remove Cart</button>
                                </div>
                            </div>
                            <div className="cartbtn_wraps">
                                <div className="quantity_product">
                                    <label>Quantity</label>
                                    <div className="productQuantyset">
                                        <button className="btn removeQuantity" disabled={cart?.data?.quantity === 0} onClick={() => cart?.data?.quantity === cart?.mainQuantity ? Dispatch(RemoveCartList(cart)) : Dispatch(removeMultipleQauntityCart({product: cart, mainQuantity: cart?.mainQuantity, mainCost: cart?.mainCost}))}>
                                            <i className="bi bi-dash-circle-fill"></i>
                                        </button>
                                        <div className="productQuantyBox">{cart?.data?.quantity}</div>
                                        <button className="btn addQuantity" onClick={() => Dispatch(addMultipleQauntityCart({product: cart, mainQuantity: cart?.mainQuantity, mainCost: cart?.mainCost}))}>
                                            <i className="bi bi-plus-circle-fill"></i>
                                        </button>
                                    </div>
                                </div>
                                <h2>${cart?.data?.unit_cost_price}</h2>
                            </div>
                        </div>
                    )}
                </li>)
            }) : <li className="emptycart"><i className="bi bi-cart4"></i>Your cart is empty</li>}
        </Scrollbars>
    </ul>
    {addToCartLists?.length > 0 && (<div className="finaltotoal_cart">
        <div className="supaTotalammount">
            <h4>Total ( {addToCartLists?.length} items )</h4>
            {/* <h1>$609</h1> */}
        </div>
        {(selfInfo?.is_admin || (selfInfo?.permissions?.includes("export-cart"))) && (<button type="button" className="btn checkoutbtn" onClick={() => ExportFunction()}>
            {/* <i className="bi bi-cart-fill"></i>  */}
            <Image src={ExportIcon} alt="ExportIcon" />
            Export
        </button>)}
    </div>)}
</section>)
}

export default Cart;